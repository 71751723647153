import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { AppRoutes } from "../routes";
//ROTA 2
import CreateProduct from "./forms/CreateProduct";
import ViewProduct from "./tables/ViewProduct";
import CreateSupplier from "./forms/CreateSupplier";
import ViewSupplier from "./tables/ViewSupplier";
import CreateCustomer from "./forms/CreateCustomer";
import ViewCustomer from "./tables/ViewCustomer";
import CreateSale from "./forms/CreateSale";
import ViewSales from "./tables/ViewSales";
import CreateBill from "./forms/CreateBill";
import ViewBill from "./tables/ViewBill";
import ViewWorker from "./tables/ViewWorker";
import ViewPayment from "./tables/ViewPayment";
import ViewRole from "./tables/ViewRole";
import ViewCategory from "./tables/ViewCategory";
import ViewReport from "./dashboard/ViewFinancial";
import ViewStatistic from "./dashboard/ViewStatistic";

// pages
import DashboardOverview from "./dashboard/Dashboard";
import Signin from "./others/Signin";
import ForgotPassword from "./others/ForgotPassword";
import ResetPassword from "./others/ResetPassword";
import Lock from "./others/Lock";
import NotFound from "./error/NotFound";
import ServerError from "./error/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import { ToastContainer } from 'react-toastify';
import PrivateOutlet from '../components/routes/private/private'



const RouteWithLoader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      <Outlet />
    </>
  );
};

const RouteWithSidebar = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, [loaded]);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }
  return (<>
    <Sidebar />
    <ToastContainer />
    <Navbar />
    <main className="content">
      <Outlet />
    </main>
    <Footer toggleSettings={toggleSettings} showSettings={showSettings} />

  </>);
};




export default () => (
  <Routes>
    <Route path={AppRoutes.Signin.path} element={<Signin />} />

    <Route element={<PrivateOutlet />}>
      <Route path="/" element={<RouteWithLoader />} >
        <Route path={"*"} element={<NotFound />} />

        <Route path={AppRoutes.Signin.path} element={<Signin />} />
        <Route path={AppRoutes.ForgotPassword.path} element={<ForgotPassword />} />
        <Route path={AppRoutes.ResetPassword.path} element={<ResetPassword />} />
        <Route path={AppRoutes.Lock.path} element={<Lock />} />
        <Route path={AppRoutes.NotFound.path} element={<NotFound />} />
        <Route path={AppRoutes.ServerError.path} element={<ServerError />} />

        <Route path="/" element={<RouteWithSidebar />} >
          <Route path={AppRoutes.DashboardOverview.path} element={<DashboardOverview />} />
          <Route path={AppRoutes.CreateProduct.path} element={<CreateProduct />} />
          <Route path={AppRoutes.ViewProduct.path} element={<ViewProduct />} />
          <Route path={AppRoutes.CreateSupplier.path} element={<CreateSupplier />} />
          <Route path={AppRoutes.ViewSupplier.path} element={<ViewSupplier />} />
          <Route path={AppRoutes.CreateCustomer.path} element={<CreateCustomer />} />
          <Route path={AppRoutes.ViewCustomer.path} element={<ViewCustomer />} />

          <Route path={AppRoutes.CreateSale.path} element={<CreateSale />} />
          <Route path={AppRoutes.ViewSales.path} element={<ViewSales />} />
          <Route path={AppRoutes.CreateBill.path} element={<CreateBill />} />
          <Route path={AppRoutes.ViewBill.path} element={<ViewBill />} />
          <Route path={AppRoutes.ViewWorker.path} element={<ViewWorker />} />
          <Route path={AppRoutes.ViewPayment.path} element={<ViewPayment />} />
          <Route path={AppRoutes.ViewRole.path} element={<ViewRole />} />
          <Route path={AppRoutes.ViewCategory.path} element={<ViewCategory />} />
          <Route path={AppRoutes.ViewReport.path} element={<ViewReport />} />
          <Route path={AppRoutes.ViewStatistic.path} element={<ViewStatistic />} />
        </Route>
      </Route>
    </Route>
  </Routes>
);
