import React, { useEffect, useState } from "react";
import { Row, Form, Modal, Button, Col, InputGroup, OverlayTrigger, Tooltip, Card, Accordion, Spinner, Table, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccordionComponent from "./AccordionComponent";
import {
  faCoins,
  faDollarSign,
  faEye,
  faMoneyBills,
  faPen,
  faPercent,
  faQuestionCircle,
  faTrashAlt,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import "moment/locale/pt-br";
import { DeleteCustomer, FetchCustomers, FindCustomerId, UpdateCustomer } from "../services/CustomerService";
import { DeleteBill, FindBillId, UpdateBill, UpdateBillNumber } from "../services/BillService";
import {
  CreateProduct,
  DeleteProduct,
  FetchProduct,
  FindProductId,
  UpdateProduct,
} from "../services/ProductService";
import { DeleteUser, FetchUsers, FindUserId, resetPassword, UpdateUser } from "../services/UserService";
import { toast } from "react-toastify";
import { DeletePayment, FetchPayment, FindPaymentId, UpdatePayment } from "../services/PaymentService";
import { DeleteSupplier, FetchSuppliers, FindSupplierId, UpdateSupplier } from "../services/SupplierService";
import { DeleteSale, FetchStatus, FindSaleId, UpdateSale } from "../services/SaleService";
import { DeleteCategory, FetchCategories, FindCategoryId, UpdateCategory } from "../services/CategoryService";
import { DeleteRole, FetchAbilities, FetchRoles, FindRoleId, UpdateRole } from "../services/RoleService";
import { Typeahead } from "react-bootstrap-typeahead";
import { IMaskInput } from "react-imask";

export const CustomerDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [houseNumber, setHouseNumber] = useState("");


  let fillForm = async () => {
    try {
      const response = await FindCustomerId(id);
      if (response.status === "success") {
        setName(response.data.name);
        setPhone(response.data.phone);
        setStreet(response.data.street);
        setDistrict(response.data.district);
        setHouseNumber(response.data.house_number);
        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);

        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cliente!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateCustomer(
        id,
        name,
        phone,
        street,
        district,
        houseNumber
      );

      if (response.data.status === "success") {
        toast.success("Cliente atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o cliente!");
      }
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-4">Dados do Cliente</h5>
          <Row>
            <Col sm={12} xl={8} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>Nome Completo</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  placeholder="Nome Exemplo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type="invalid">
                  {error.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} xl={4} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Telefone <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  as={IMaskInput}
                  mask="(00) 00000-0000"
                  placeholder="(00) 00000-0000"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} xl={3} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Rua <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  placeholder="Rua Exemplo"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {error.street}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4} xl={3} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Bairro <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  placeholder="Bairro Exemplo"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={4} xl={2} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Número <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="number"
                  placeholder="000"
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteCustomerModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteCustomer(id);
      if (response.data.status === "success") {
        toast.success("Cliente deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar o cliente");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o cliente!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            size={"md"}
            onClick={handleDelete}
            className="btn btn-danger"
          >
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const BillDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [supplierId, setSupplierId] = useState("");
  const [supplier, setSupplier] = useState("");
  const [description, setDescription] = useState("");
  const [bills, setBills] = useState([]);

  const [parcelId, setParcelId] = useState("");
  const [parcelStatusId, setParcelStatusId] = useState("");


  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const { data: suppliers, isLoading: suppliersLoading } = FetchSuppliers();

  let fillForm = async () => {
    try {
      const response = await FindBillId(id);
      if (response.status === "success") {

        setBills(response.data.numbers);
        setSupplierId(response.data.supplier.id);
        setSupplier(response.data.supplier.name);
        setDescription(response.data.description);
        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);

        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o produto!");
    }
  };

  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateBill(
        id,
        supplierId,
        description
      );
      if (response.data.status === "success") {
        toast.success("Boleto atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o boleto!");
      }
    }
  };

  let handleParcelPayment = async (e) => {
    if (e) e.preventDefault();
    try {
      const response = await UpdateBillNumber(
        parcelId,
        parcelStatusId,
      );
      if (response.data.status === "success") {
        toast.success("Parcela atualizada com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao atualizar a parcela!");
    }
  }

  useEffect(() => {
    if (parcelId) handleParcelPayment();
  }, [parcelId]);


  const renderTable = () => {
    if (bills.length === 0) {
      return (
        <tr>
          <td colSpan="6" className="text-center">
            Não há parcelas registradas
          </td>
        </tr>
      );
    }

    return bills.map((parcel) => {
      return (
        <tr key={parcel.id}>
          <td>
            <span className="fw-bold text-info">#{parcel.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">
              <td>{parcel.barcode}</td>
            </span>
          </td>
          <td>
            <span className="fw-bold text-success">
              R$ {parseFloat(parcel.amount).toFixed(2)}
            </span>
          </td>
          <td>
            <Badge
              bg=""
              style={{ backgroundColor: "#262b40" }}
              className="badge me-1"
            > {parcel.due_date}</Badge>
          </td>
          <td>
            <Badge
              bg=""
              style={{
                backgroundColor: parcel.status.background_color,
                color: parcel.status.color,
              }}
              className="badge me-1"
            >
              {parcel.status.name}{" "}
            </Badge>
          </td>
          {parcel.status.id === 1 ? (
            <td>
              <Button
                size={"xs"}
                className="btn btn-info"
                onClick={() => { setParcelId(parcel.id); setParcelStatusId(2) }}>
                Pagar
              </Button>
            </td>
          ) : (
            <td>
              <Button
                size={"xs"}
                className="btn btn-danger"
                onClick={() => { setParcelId(parcel.id); setParcelStatusId(1) }}>
                Reverter
              </Button>
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-4">Dados do Emitente</h5>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Empresa</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id="supplier"
                  className="is-invalid"
                  labelKey={(suppliers) => `${suppliers.name}`}
                  onChange={(value) => value[0] && setSupplierId(value[0].id)}
                  options={suppliers}
                  defaultInputValue={supplier}
                  placeholder="Selecione um Fornecedor"
                  isInvalid={!!error.supplierId}
                  isLoading={suppliersLoading}
                />

                <Form.Control.Feedback type="invalid">
                  {error.supplierId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Descrição <small>(Opcional)</small></Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  isInvalid={!!error.description}
                />
                <Form.Control.Feedback type="invalid">
                  {error.description}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item>
              <Accordion.Button variant="link" className="w-100 d-flex justify-content-between">
                <span className="h6 mb-0 fw-bold">
                  Boletos Cadastrados
                </span>
              </Accordion.Button>
              <Accordion.Body className="p-0">
                <Table hover className="user-table align-items-center">
                  <thead>
                    <tr>
                      <th className="border-bottom">#</th>
                      <th className="border-bottom">CÓD. BARRAS</th>
                      <th className="border-bottom">VALOR PARCELAS</th>
                      <th className="border-bottom">DATA VENCIMENTO</th>
                      <th className="border-bottom">STATUS</th>
                      <th className="border-bottom">AÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>{renderTable()}</>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteBillModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteBill(id);
      if (response.data.status === "success") {
        toast.success("Boleto deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar o boleto");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o boleto!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const ProductDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [supplierId, setSupplierId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [supplier, setSupplier] = useState("");
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState(1);
  const [productCode, setProductCode] = useState("");
  const [name, setName] = useState("");
  const [weight, setWeight] = useState(0);
  const [buyCost, setBuyCost] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [barcode, setBarcode] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const { data: suppliers, isLoading: suppliersLoading } = FetchSuppliers();
  const { data: categories, isLoading: categoryLoading } = FetchCategories();

  let fillForm = async () => {
    try {
      const response = await FindProductId(id);
      if (response.status === "success") {
        setSupplierId(response.data.supplier.id);
        setCategoryId(response.data.category.id);
        setSupplier(response.data.supplier.name);
        setCategory(response.data.category.name);
        setAmount(response.data.storage);
        setProductCode(
          response.data.code != null ? response.data.code : undefined
        );
        setName(response.data.name);
        setWeight(response.data.weight);
        setBuyCost(
          response.data.buy_cost != null ? response.data.buy_cost : undefined
        );
        setSellPrice(response.data.sell_price);
        setBarcode(response.data.barcode);
        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);

        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o produto!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateProduct(
        id,
        supplierId,
        categoryId,
        amount,
        barcode,
        productCode,
        name,
        weight,
        buyCost,
        sellPrice
      );
      if (response.data.status === "success") {
        toast.success("Produto atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o produto!");
      }
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-4">Dados do Fornecimento</h5>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Fornecedor</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id="supplier"
                  className="is-invalid"
                  labelKey={(suppliers) => `${suppliers.name}`}
                  onChange={(value) => value[0] && setSupplierId(value[0].id)}
                  options={suppliers}
                  defaultInputValue={supplier}
                  placeholder="Selecione um Fornecedor"
                  isInvalid={!!error.supplierId}
                  isLoading={suppliersLoading}
                />

                <Form.Control.Feedback type="invalid">
                  {error.supplierId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Categoria</Form.Label>
                <Typeahead
                  disabled={isDisabled}
                  id="category"
                  className="is-invalid"
                  labelKey={(categories) => `${categories.name}`}
                  onChange={(value) => value[0] && setCategoryId(value[0].id)}
                  options={categories}
                  defaultInputValue={category}
                  placeholder="Selecione uma Categoria"
                  isInvalid={!!error.categoryId}
                  isLoading={categoryLoading}
                />
                <Form.Control.Feedback type="invalid">
                  {error.categoryId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Estoque</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  isInvalid={!!error.amount}
                />
                <Form.Control.Feedback type="invalid">
                  {error.amount}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Dados do Produto</h5>
          <Row>
            <Col sm={6} xl={3} className="mb-3">
              <Form.Group>
                <Form.Label className="me-1">Cód. Barras</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="number"
                  placeholder="0000000000000"
                  as={IMaskInput}
                  mask="0000000000000"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  isInvalid={!!error.barcode}
                />
                <Form.Control.Feedback type="invalid">
                  {error.barcode}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col col={4}>
              <Form.Group>
                <Form.Label>
                  Cód. Produto <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="number"
                  placeholder="0"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                  isInvalid={!!error.product_code}
                />
                <Form.Control.Feedback type="invalid">
                  {error.product_code}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  placeholder="Produto Exemplo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type="invalid">
                  {error.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} xl={6} className="mb-3">
              <Form.Group>
                <Form.Label className="required">Peso (KG)</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  isInvalid={!!error.weight}
                />
                <Form.Control.Feedback type="invalid">
                  {error.weight}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} xl={3} className="mb-3">
              <Form.Group>
                <Form.Label>
                  Custo de Compra <small>(Opcional)</small>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    className={`${error.sell_price ? "is-invalid" : ""} ${isDisabled ? "disabled" : ""
                      }`}
                  >
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={isDisabled}
                    type="number"
                    placeholder="R$ 00.00"
                    value={buyCost}
                    onChange={(e) => setBuyCost(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group>
                <Form.Label>Preço de Venda</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text
                    className={`${error.sell_price ? "is-invalid" : ""} ${isDisabled ? "disabled" : ""
                      }`}
                  >
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={isDisabled}
                    type="number"
                    placeholder="R$ 00.00"
                    value={sellPrice}
                    onChange={(e) => setSellPrice(e.target.value)}
                    isInvalid={!!error.sell_price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.sell_price}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteProductModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteProduct(id);
      if (response.data.status === "success") {
        toast.success("Produto deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar o produto");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o produto!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const SaleDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [customerId, setCustomerId] = useState("");
  const [workerId, setWorkerId] = useState("");
  const [customer, setCustomer] = useState("");
  const [worker, setWorker] = useState("");

  const [total, setTotal] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [discount, setDiscount] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [payment, setPayment] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [tax, setTax] = useState("");
  const [profit, setProfit] = useState("");


  const [statusId, setStatusId] = useState("");
  const [status, setStatus] = useState("");

  const [products, setProducts] = useState([]);

  const { data: statuses, isLoading: statusLoading } = FetchStatus();
  const { data: payments, isLoading: paymentsLoading } = FetchPayment();
  const { data: customers, isLoading: customerLoading } = FetchCustomers();
  const { data: workers, isLoading: workersLoading } = FetchUsers();



  let fillForm = async () => {
    try {
      const response = await FindSaleId(id);
      if (response.status === "success") {

        setCustomerId(response.data.customer.id);
        setWorkerId(response.data.worker.id);
        setCustomer(response.data.customer.name);
        setWorker(response.data.worker.name);

        setTotal(response.data.total);
        setSubTotal(response.data.subtotal);
        setDiscount(response.data.discount_amount);
        setPaidAmount(response.data.paid_amount);
        setPaymentId(response.data.payment_method.id);
        setPayment(response.data.payment_method.name);
        setProfit(response.data.profit_amount);
        setTax(response.data.tax_amount);

        setStatusId(response.data.status.id);
        setStatus(response.data.status.name);

        setProducts(response.data.products);
        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);
        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o venda!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateSale(
        id,
        customerId,
        workerId,
        paymentId,
        statusId,
        paidAmount,
      );
      if (response.data.status === "success") {
        toast.success("Venda atualizada com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar a venda!");
      }
    }
  };

  const renderTable = () => {
    if (products.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            Não há produtos registrados
          </td>
        </tr>
      );
    }
    return products.map((product) => {
      return (
        <tr key={product.id}>
          <td className="fw-bold text-info">
            #{product.product_info.barcode.toString().padStart(12, 0)}
          </td>
          <td className="fw-normal">
            {product.quantity} UN {product.weight > 0 && `/ ${product.weight} KG`}
          </td>
          <td className="fw-normal text-uppercase">
            {product.product_info.supplier} {product.product_info.product}<br />x R$ {parseFloat(product.unit_price).toFixed(2)}
          </td>
          <td className="fw-bold text-success">
            R$ {parseFloat(product.total_price).toFixed(2)}
          </td>
          <td className="fw-bold text-success">
            R$ {parseFloat(product.profit).toFixed(2)}
          </td>
        </tr>
      );
    });
  };


  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className="mb-2">Dados da Venda</h5>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Cliente</Form.Label>
                <Typeahead
                  id="worker"
                  labelKey={customers => `${customers.name}`}
                  onChange={value => value[0] && setCustomerId(value[0].id)}
                  options={customers}
                  disabled={isDisabled}
                  placeholder="Selecione um Cliente"
                  defaultInputValue={customer}
                  isLoading={customerLoading}
                />
              </Form.Group>

            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Funcionário</Form.Label>
                <Typeahead
                  id="worker"
                  labelKey={workers => `${workers.username}`}
                  onChange={value => value[0] && setWorkerId(value[0].id)}
                  options={workers}
                  disabled={isDisabled}
                  placeholder="Selecione um Funcionário"
                  isLoading={workersLoading}
                  defaultInputValue={worker}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Desconto</Form.Label>
                <InputGroup className="align-items-center">
                  <InputGroup.Text style={{ border: 'none' }}>
                    <FontAwesomeIcon icon={faPercent} color={'#0948B3'} />
                  </InputGroup.Text>
                  <span className="font-bold text-info">{discount}%</span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Taxa</Form.Label>
                <InputGroup className="align-items-center">
                  <InputGroup.Text style={{ border: 'none', paddingLeft: 'inherit' }}>
                    <FontAwesomeIcon icon={faMoneyBills} color={'#FA5252'} />
                  </InputGroup.Text>
                  <span className="font-bold text-danger">{tax}</span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Lucro</Form.Label>
                <InputGroup className="align-items-center">
                  <InputGroup.Text style={{ border: 'none', paddingLeft: 'inherit' }}>
                    <FontAwesomeIcon icon={faDollarSign} color={'#05A677'} />
                  </InputGroup.Text>
                  <span className="font-bold text-success">{profit}</span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Status</Form.Label> {statusId === 1 && <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip style={{ margin: 0 }}>Para o status de uma venda ser concluida, o Valor Pago deve ser igual ao Valor Total.</Tooltip>}>
                  <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
                </OverlayTrigger>}
                <Typeahead
                  id="status"
                  labelKey={statuses => `${statuses.name}`}
                  // onChange={value => value[0] && setStatusId(value[0].id)}
                  options={statuses}
                  disabled
                  placeholder="Selecione o Status"
                  defaultInputValue={status}
                  isLoading={statusLoading}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h5 className="mb-2">Dados do Pagamento</h5>
            <Col md={3} className="mb-3">
              <Form.Group>
                <Form.Label>SubTotal</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text className="disabled">
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="R$ 00.00"
                    value={subTotal}
                    onChange={(e) => setSubTotal(e.target.value)}
                    isInvalid={!!error.subtotal}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.totalValue}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group>
                <Form.Label>Total</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text className="disabled">
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="R$ 00.00"
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    isInvalid={!!error.totalValue}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.totalValue}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Valor Pago</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text className={`${error.paid_amount ? "is-invalid" : ""} ${isDisabled ? "disabled" : ""
                    }`}>
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    as={IMaskInput}
                    disabled={isDisabled}
                    placeholder="R$ 00.00"
                    max={total.replace(",", ".")}
                    mask={Number}
                    value={paidAmount}
                    onChange={(e) => setPaidAmount(e.target.value)}
                    isInvalid={!!error.paid_amount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.paid_amount}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={{ offset: 6, col: 6 }} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Métd. Pagamento</Form.Label>
                <Typeahead
                  id="worker"
                  labelKey={payments => `${payments.name}`}
                  onChange={value => value[0] && setPaymentId(value[0].id)}
                  options={payments}
                  disabled={isDisabled}
                  placeholder="Selecione uma Forma de Pagamento"
                  defaultInputValue={payment}
                  isLoading={paymentsLoading}
                />
              </Form.Group>
            </Col>
          </Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item>
              <Accordion.Button variant="link" className="w-100 d-flex justify-content-between">
                <span className="h6 mb-0 fw-bold">
                  Produtos Cadastrados
                </span>
              </Accordion.Button>
              <Accordion.Body className="p-0">
                <Table hover className="user-table align-items-center">
                  <thead>
                    <tr>
                      <th className="border-bottom">#</th>
                      <th className="border-bottom">QTD UN</th>
                      <th className="border-bottom">PRODUTO</th>
                      <th className="border-bottom">VALOR TOTAL</th>
                      <th className="border-bottom">LUCRO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>{renderTable()}</>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteSaleModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteSale(id);
      if (response.data.status === "success") {
        toast.success("Venda deletada com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar a venda");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar a venda!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const SupplierDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const { data: categories, isLoading: categoryLoading } = FetchCategories();

  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [category, setCategory] = useState("");
  const [CNPJ, setCNPJ] = useState("");
  const [minOrder, setMinOrder] = useState("");
  const [seller, setSeller] = useState("");
  const [sellerPhone, setSellerPhone] = useState("");

  let fillForm = async () => {
    try {
      const response = await FindSupplierId(id);
      if (response.status === "success") {
        setName(response.data.name);
        setCategoryId(response.data.category.id);
        setCategory(response.data.category.name);
        setCNPJ(response.data.CNPJ);
        setMinOrder(response.data.min_order);
        setSeller(response.data.seller);
        setSellerPhone(response.data.seller_phone);
        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);

        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o produto!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateSupplier(
        id,
        name,
        categoryId,
        CNPJ,
        minOrder,
        seller,
        sellerPhone
      );

      if (response.data.status === "success") {
        toast.success("Fornecedor atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o fornecedor!");
      }
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-4">Dados do Fornecedor</h5>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>Nome da Empresa</Form.Label>
                <Form.Control
                  disabled={isDisabled}
                  type="text"
                  placeholder="Empresa Exemplo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type="invalid">
                  {error.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Categoria</Form.Label>
                <Typeahead
                  id="category_id"
                  disabled={isDisabled}
                  className="is-invalid"
                  labelKey={(categories) => `${categories.name}`}
                  onChange={(value) => value[0] && setCategoryId(value[0].id)}
                  options={categories}
                  placeholder="Selecione uma Categoria"
                  isInvalid={!!error.categoryId}
                  isLoading={categoryLoading}
                  defaultInputValue={category}
                />
                <Form.Control.Feedback type="invalid">
                  {error.categoryId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  CNPJ <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={isDisabled}
                  placeholder="XX.XXX.XXX/0001-XX"
                  value={CNPJ}
                  onChange={(e) => setCNPJ(e.target.value)}
                  isInvalid={!!error.CNPJ}
                />
                <Form.Control.Feedback type="invalid">
                  {error.CNPJ}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Pedido Mínimo <small>(Opcional)</small>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text className={`${error.sell_price ? "is-invalid" : ""} ${isDisabled ? "disabled" : ""
                    }`}>
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    disabled={isDisabled}
                    placeholder="R$ 00.00"
                    value={minOrder}
                    onChange={(e) => setMinOrder(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Dados do Vendedor</h5>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="seller">
                <Form.Label className="me-1">
                  Nome <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={isDisabled}
                  placeholder="Nome Exemplo"
                  value={seller}
                  onChange={(e) => setSeller(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group id="phone_number">
                <Form.Label>
                  Telefone <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={isDisabled}
                  as={IMaskInput}
                  mask="(00) 00000-0000"
                  placeholder="(00) 00000-0000"
                  value={sellerPhone}
                  onChange={(e) => setSellerPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteSupplierModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteSupplier(id);
      if (response.data.status === "success") {
        toast.success("Fornecedor deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar fornecedor");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o fornecedor!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const RoleDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [name, setName] = useState("");
  const [salary, setSalary] = useState("");
  const [abilities, setAbilities] = useState([]);
  const { data: ability, isLoading: abilitiesLoading } = FetchAbilities();


  let fillForm = async () => {
    try {
      const response = await FindRoleId(id);
      if (response.status === "success") {


        setName(response.data.name);
        setSalary(response.data.salary);
        setAbilities(response.data.abilities);

        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);
        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o cargo!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateRole(
        id,
        name,
        salary,
        abilities,
      );
      if (response.data.status === "success") {
        toast.success("Cargo atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o Cargo!");
      }
    }
  };

  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className="mb-2">Dados do Cargo</h5>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled={isDisabled} type="text" placeholder='Nome Exemplo' value={name} onChange={e => setName(e.target.value)} isInvalid={!!error.name} />
                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>

              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Salário</Form.Label>
                <Form.Control disabled={isDisabled} type="text" value={salary} onChange={e => setSalary(e.target.value)} placeholder='R$ 00.00' />
              </Form.Group>
            </Col>
            <Col md={12} className="mb-3">
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>Permissões do Cargo</Form.Label>  <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip style={{ margin: 0 }}>Você pode selecionar mais de uma opção segurando a tecla CTRL e clicando.</Tooltip>}>
                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
                  </OverlayTrigger>
                  <Typeahead
                    id="abilities"
                    disabled={isDisabled}
                    className="is-invalid"
                    labelKey={ability => `${ability.label}`}
                    onChange={value => setAbilities(value)}
                    options={ability}
                    placeholder="Selecione um Cargo"
                    multiple
                    isLoading={abilitiesLoading}
                    selected={ability.filter((ability) => abilities.includes(ability.value))}
                    renderMenuItemChildren={(ability) => (
                      <React.Fragment>
                        <div key={ability.value}>{ability.label}</div>
                        <strong><small>{ability.title}</small></strong>
                      </React.Fragment>
                    )}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteRoleModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteRole(id);
      if (response.data.status === "success") {
        toast.success("Cargo deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar o cargo");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o cargo!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const PaymentDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [name, setName] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [color, setColor] = useState("");
  const [status, setStatus] = useState("");
  const [tax, setTax] = useState("");


  let fillForm = async () => {
    try {
      const response = await FindPaymentId(id);
      if (response.status === "success") {

        setName(response.data.name);
        setBackgroundColor(response.data.background_color);
        setColor(response.data.color);
        setStatus(response.data.set_status);
        setTax(response.data.tax);

        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);
        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o pagamento!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdatePayment(
        id,
        name,
        backgroundColor,
        color,
        status,
        tax
      );
      if (response.data.status === "success") {
        toast.success("Pagamento atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o Pagamento!");
      }
    }
  };

  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className="mb-2">Dados do Métd. Pagamento</h5>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled={isDisabled} type="text" placeholder='Nome Exemplo' value={name} onChange={e => setName(e.target.value)} isInvalid={!!error.name} />
                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>

              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Taxa</Form.Label>
                <Form.Control disabled={isDisabled} type="number" placeholder='0%' value={tax} onChange={e => setTax(e.target.value)} isInvalid={!!error.tax} />
                <Form.Control.Feedback type="invalid">{error.tax}</Form.Control.Feedback>

              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>

                <Form.Label>Definir status de venda:</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Em Aberto"
                    type={"radio"}
                    value='1' disabled={isDisabled} onChange={e => setStatus(1)} />
                  <Form.Check
                    inline
                    label="Concluida"
                    type={"radio"}
                    value='2' disabled={isDisabled} onChange={e => setStatus(2)} />
                </div>
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Cor do texto</Form.Label>
                <Form.Control disabled={isDisabled} type="color" value={color} onChange={e => setColor(e.target.value)} placeholder='Nome Exemplo' />
              </Form.Group>
            </Col>
            <Col md={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Cor de Fundo</Form.Label>
                <Form.Control disabled={isDisabled} type="color" value={backgroundColor} onChange={e => setBackgroundColor(e.target.value)} isInvalid={!!error.background_color} />
                <Form.Control.Feedback type="invalid">{error.background_color}</Form.Control.Feedback>

              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeletePaymentModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeletePayment(id);
      if (response.data.status === "success") {
        toast.success("Método deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar o método");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o método!");
    }
  };

  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const CategoryDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const [name, setName] = useState("");


  let fillForm = async () => {
    try {
      const response = await FindCategoryId(id);
      if (response.status === "success") {

        setName(response.data.name);

        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);

        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar a categoria!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateCategory(
        id,
        name
      );
      if (response.data.status === "success") {
        toast.success("Categoria atualizada com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar a categoria!");
      }
    }
  };

  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className="mb-2">Dados da Categoria</h5>
            <Col md={12} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled={isDisabled} type="text" placeholder='Nome Exemplo' value={name} onChange={e => setName(e.target.value)} isInvalid={!!error.name} />
                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>

              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteCategoryModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteCategory(id);
      if (response.data.status === "success") {
        toast.success("Categoria deletada com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar categoria");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar a categoria!");
    }
  };

  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const UserDetailModal = ({ id, setRefreshState, refreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
  const [error, setError] = useState({});
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const { data: roles, isLoading: roleLoading } = FetchRoles();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [roleId, setRoleId] = useState();


  let fillForm = async () => {
    try {
      const response = await FindUserId(id);
      if (response.status === "success") {

        setUsername(response.data.username);
        setEmail(response.data.email);
        setRoleId(response.data.role.id);
        setRole(response.data.role.name);

        setCreatedAt(response.data.created_at);
        setUpdatedAt(response.data.updated_at);

        setShowDefault(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar o funcionário!");
    }
  };
  let handleEditable = async () => {
    isDisabled === true ? setDisabled(false) : setDisabled(true);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await UpdateUser(
        id,
        username,
        email,
        roleId
      );
      if (response.data.status === "success") {
        toast.success("Funcionário atualizado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao atualizar o funcionário!");
      }
    }
  };

  const resetUserPassword = async () => {
    try {
      const response = await resetPassword(
        id
      );
      if (response.data.status === "success") {
        toast.success("Senha resetada com sucesso! Definida para: Troca123");
        handleClose();
        setError("");
        setDisabled(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao resetar a senha!");
      }
    }
  };


  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={fillForm}>
        <FontAwesomeIcon icon={faEye} className="me-2" /> Detalhes
      </span>
      <Modal
        size={"xl"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ver Detalhes</Modal.Title>

          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5 className="mb-2">Dados do Funcionário</h5>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled={isDisabled} type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder='Nome Exemplo' isInvalid={!!error.username} />
                <Form.Control.Feedback type="invalid">{error.username}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Email</Form.Label>
                <Form.Control disabled={isDisabled} type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder='exemplo@exemplo.com' isInvalid={!!error.email} />
                <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Form.Group className="mb-2">
              <Form.Label>Cargo</Form.Label>
              <Typeahead
                disabled={isDisabled}
                id="role"
                className="is-invalid"
                labelKey={roles => `${roles.name}`}
                onChange={value => value[0] && setRoleId(value[0].id)}
                options={roles}
                placeholder="Selecione um Cargo"
                isLoading={roleLoading}
                defaultInputValue={role}
              />
              <Form.Control.Feedback type="invalid">{error.roleId}</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div>
            <Col>
              <small>Criado em: {createdAt}</small>
            </Col>
            <Col>
              <small>Atualizado em: {updatedAt}</small>
            </Col>
          </div>
          <div>
            <Button
              size={"md"}
              className="btn btn-danger me-3"
              onClick={resetUserPassword}
            >
              <FontAwesomeIcon icon={faUndo} className='me-1' />Resetar Senha
            </Button>
            <Button
              size={"md"}
              className="btn btn-gray me-3"
              onClick={handleEditable}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {!isDisabled && (
              <Button
                size={"md"}
                onClick={handleSubmit}
                className="btn btn-success"
              >
                Salvar
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const DeleteUserModal = ({ id, refreshState, setRefreshState }) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  let handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await DeleteUser(id);
      if (response.data.status === "success") {
        toast.success("Funcionário deletado com sucesso!");
        refreshState === true ? setRefreshState(false) : setRefreshState(true);
        handleClose();
      } else {
        throw new Error("Falha ao deletar funcionário");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao deletar o funcionário!");
    }
  };
  return (
    <div      
    onKeyDown={e => e.stopPropagation()}
    onClick={e => e.stopPropagation()}>
      <span onClick={() => setShowDefault(true)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Deletar
      </span>
      <Modal
        size={"md"}
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Deletar Dados</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center text-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="me-2 fa-6x text-danger col-12"
            />
            <span className="h4 mb-3 mt-4 col-12">Você tem certeza?</span>
          </Row>

          <Row className="justify-content-center text-center">
            <p className="mb-0">Você realmente deseja deletar esses dados?</p>
            <p>Esse processo não pode ser desfeito.</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            size={"md"}
            className="btn btn-gray me-3"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button size={"md"} onClick={handleDelete} className="btn btn-danger">
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
