import { useEffect, useState } from 'react';
import api from '../api'



export const FindActiveRole = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/role?page=${page}&name=${filter.search ?? ''}`)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.roles);
                setPagination(response.data.data.pagination);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);

            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };

}

export const FindRoleId = async (id) => {
    return await api.get(`v1/role/${id}`).then(function (response) {
        var res = response.data;
        return { data: res.data, status: res.status };
    });
};

export const CreateRole = async (name, salary, abilities) => {
    let valuesArray = [];
    if (abilities != null) {
        abilities.forEach(ability => {
            valuesArray.push(ability.value);
        });
    }

    const response = await api.post('/v1/role', {
        name: name,
        salary: (salary !== "") ? salary : undefined,
        abilities: valuesArray,
    });

    return response;
};

export const UpdateRole = async (id, name, salary, abilities) => {
    let valuesArray = [];
    if (abilities !== null && abilities !== "[]") {
        abilities.forEach(ability => {
            valuesArray.push(ability.value);
        });
    }

    const response = await api.put(`/v1/role/${id}`, {
        name: name,
        salary: (salary !== "") ? salary : undefined,
        abilities: valuesArray,
    });

    return response;
};

export const DeleteRole = async (id) => {
    return await api.delete(`/v1/role/${id}`);
};

export const FetchRoles = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/list/roles`)
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);

                setData(response.data.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])
    return { data, isLoading, isEmpty };
}

export const FetchAbilities = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/list/abilities`)
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);

                setData(response.data.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])
    return { data, isLoading, isEmpty };
}



