import { useEffect, useState } from 'react';
import api from '../api'



export const FindActivePayment = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/payment?page=${page}&name=${filter.search ?? ''}`)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.payments);
                setPagination(response.data.data.pagination);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);

            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };

}

export const FindPaymentId = async (id) => {
    return await api.get(`v1/payment/${id}`).then(function (response) {
      var res = response.data;
      return { data: res.data, status: res.status };
    });
  };

export const CreatePayment = async (name, background_color, color, set_status, tax) => {
    const response = await api.post('/v1/payment', {
        name: name,
        background_color: background_color,
        color: color,
        set_status: set_status,
        tax: (tax !== "") ? tax : undefined,
    });

    return response;
};


export const UpdatePayment = async (id, name, background_color, color, set_status, tax) => {
    const response = await api.put(`/v1/payment/${id}`, {
        name: name,
        background_color: background_color,
        color: color,
        set_status: set_status,
        tax: (tax !== "") ? tax : undefined,
    });

    return response;
};

export const DeletePayment = async (id) => {
    return await api.delete(`/v1/payment/${id}`);
};

export const FetchPayment = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
    setIsLoading(true);
    api.get(`v1/list/payments`)
        .then(response => {
            if (!response.data.status === "error") setIsEmpty(true);
            setData(response.data.data);
            setIsLoading(false);

        })
        .catch(error => {
            setIsEmpty(true);
            setIsLoading(false);
        });
    }, [])
    return { data, isLoading, isEmpty };
}
