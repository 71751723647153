
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faBarcode, faCalendarAlt, faCashRegister, faChartArea, faChartBar, faChartLine, faCheck, faChevronDown, faCoins, faEdit, faEllipsisH, faEraser, faEye, faFlagUsa, faFolderOpen, faGlobeEurope, faLandmark, faListOl, faPaperclip, faPercent, faQuestionCircle, faTrashAlt, faUser, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faAngular, faBootstrap, faReact, faVuejs } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar, Dropdown, ButtonGroup, Form, Table, OverlayTrigger, Tooltip, Container } from 'react-bootstrap';
import { CircleChart, BarChart, SalesValueChart, SalesValueChartphone, LineChart, FinancialHeaderChart, WeekdaysEarningsChart } from "./Charts";

import Profile1 from "../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../assets/img/profile-cover.jpg";
import { CategoryDetailModal, DeleteCategoryModal, DeletePaymentModal, DeleteRoleModal, PaymentDetailModal, RoleDetailModal } from "./Modals";
import teamMembers from "../data/teamMembers";
import 'moment/locale/pt-br';
import moment from "moment-timezone";
import { FetchCustomers } from "../services/CustomerService";
import { Typeahead } from "react-bootstrap-typeahead";
import { GetCurrentUser } from "../services/UserService";
import { FetchPayment } from "../services/PaymentService";
import { IMaskInput } from 'react-imask';
import { FindProduct } from "../services/ProductService";
import { toast } from "react-toastify";
import { CreateSale, FetchSaleNumber } from "../services/SaleService";


export const ProfileCardWidget = () => {
  return (
    <Card border="light" className="text-center p-0 mb-4">
      <div style={{ backgroundImage: `url(${ProfileCover})` }} className="profile-cover rounded-top" />
      <Card.Body className="pb-5">
        <Card.Img src={Profile1} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
        <Card.Title>Neil Sims</Card.Title>
        <Card.Subtitle className="fw-normal">Senior Software Engineer</Card.Subtitle>
        <Card.Text className="text-gray mb-4">New York, USA</Card.Text>

        <Button variant="primary" size="sm" className="me-2">
          <FontAwesomeIcon icon={faUserPlus} className="me-1" /> Connect
        </Button>
        <Button variant="secondary" size="sm">Send Message</Button>
      </Card.Body>
    </Card>
  );
};

export const ChoosePhotoWidget = (props) => {
  const { title, photo } = props;

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">{title}</h5>
        <div className="d-xl-flex align-items-center">
          <div className="user-avatar xl-avatar">
            <Image fluid rounded src={photo} />
          </div>
          <div className="file-field">
            <div className="d-flex justify-content-xl-center ms-xl-3">
              <div className="d-flex">
                <span className="icon icon-md">
                  <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                </span>
                <input type="file" />
                <div className="d-md-block text-start">
                  <div className="fw-normal text-dark mb-1">Choose Image</div>
                  <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const CustomersWidget = ({ setCustomerData, response }) => {
  const ref = React.createRef();
  const [customerId, setCustomerId] = useState(1);
  const { data: customers, isLoading: customerLoading } = FetchCustomers();
  const { data: user, isLoading: isLoadingUser } = GetCurrentUser();
  const { data: saleNumber } = FetchSaleNumber();

  useEffect(() => {
    if (customerId && user.id)
      setCustomerData({ customerId: customerId, userId: user.id });
  }, [customerId, user.id, response]);

  useEffect(() => {
    if (response) {
      ref.current.clear()
      ref.current.setState({
        text: 'Não Cadastrado',
      });
      setCustomerId(1);
    }
  }, [response]);

  const today = moment();
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <span>CLIENTES CADASTRADOS</span>
          <span>{today.format('DD/MM/yyyy H:mm')}</span>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <span className="icon icon-md">
            <FontAwesomeIcon icon={faUser} className="me-3" />
          </span>
          <Form.Group className="mb-2 col">
            <Typeahead
              ref={ref}
              id="customer"
              labelKey={customers => `${customers.name}`}
              onChange={value => value[0] && setCustomerId(value[0].id)}
              options={customers}
              placeholder="Selecione um cliente"
              isLoading={customerLoading}
              defaultInputValue={"Não Cadastrado"}
            />

          </Form.Group>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-between">
          {isLoadingUser ? (
            <span>Operador: <code>Carregando...</code></span>
          ) : (
            <span>Operador: <code>{user.username}</code></span>
          )}
          <span>Venda: <code>#{(saleNumber.id) + (1)}</code></span>
        </div>
      </Card.Footer>
    </Card>
  );
};

export const FindProductWidget = ({ setProductData, SaleData, setError, setResponse }) => {
  const [tempBarcode, setTempBarcode] = useState("");
  const [unitPrice, setUnitPrice] = useState("00,00");
  const [weight, setWeight] = useState("0.000");
  const [amount, setAmount] = useState(1);
  const [itemDesc, setItemDesc] = useState("casa de ração jardim sul");
  const [barcode, setBarcode] = useState("");


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setBarcode(tempBarcode);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (barcode) {
        try {
          const { data } = await FindProduct(barcode);
          const { id, supplier, name, sell_price, weight } = data;
          setBarcode("");
          setTempBarcode("");
          setItemDesc(`${supplier.name} ${name}`);
          setUnitPrice(sell_price);
          setWeight(weight);
          setAmount(1);

          let response = {
            id,
            barcode,
            product: `${supplier.name} ${name}`,
            amount: parseInt(amount),
            price: sell_price,
            weight,
            total_price: (sell_price * amount).toFixed(2),
          };
          setProductData(response);

        } catch (error) {
          setTempBarcode("");
          toast.error('Produto não encontrado!');
        }
      }
    }
    fetchData();
  }, [barcode, setProductData]);

  let handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      let customerId = SaleData.customerData.customerId;
      let userId = SaleData.customerData.userId;
      let total = SaleData.paymentData.price.total;
      let subtotal = SaleData.paymentData.price.subtotal;
      let discount = SaleData.paymentData.discount;
      let paidValue = SaleData.paymentData.paidAmount;
      let payment = SaleData.paymentData.paymentId;
      let cart = SaleData.cartArray;
      let cartIds = cart.map(item => item.id);
      let cartWeight = cart.map(item => item.weight);
      let cartSellPrice = cart.map(item => item.price);
      let cartQuantity = cart.map(item => item.amount);


      const response = await CreateSale(customerId, userId, total, subtotal, discount, paidValue, payment, cartIds, cartWeight, cartSellPrice, cartQuantity);

      if (response.data.status === 'success') {
        setItemDesc("casa de ração jardim sul")
        setUnitPrice("00.00")
        setWeight("0.000")
        setAmount(1)
        toast.success('Venda cadastrada com sucesso!')
        setError("");
        setResponse(true);

      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        setError(err.response.data.errors);
        toast.warn('Verifique os campos obrigatórios!');

      } else {
        toast.error('Ocorreu um erro ao cadastrar a venda!');
      };

    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-2">
      <Card.Header>
        <div className="d-flex">
          <span>TERMINAL DE PRODUTOS <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
            <Tooltip style={{ margin: 0 }}>Para adicionar um produto ao carrinho, basta inserir o código de barras no campo da direita.</Tooltip>}>
            <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
          </OverlayTrigger></span>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-row mb-4">
          <div className="d-flex align-items-center w-25 me-3">
            <span className="icon icon-md">
              <FontAwesomeIcon icon={faListOl} className="me-3" />
            </span>
            <Form.Group id="seller" className="w-75">
              <Form.Control type="text" value={amount} onChange={(e) => { setAmount(e.target.value) }} />
            </Form.Group>
          </div>
          <div className="d-flex align-items-center w-75">
            <span className="icon icon-md">
              <FontAwesomeIcon icon={faBarcode} className="me-3" />
            </span>
            <Form.Group className="w-100">
              <Form.Control type="text" value={tempBarcode} onChange={(e) => { setTempBarcode(e.target.value) }} onKeyDown={handleKeyDown} placeholder="0000000000000" />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <h1 className="text-uppercase">{itemDesc}</h1>
        </div>

        <div className="row">
          <>
            <div className="col-3">
              <span className="text-info">VALOR UNITÁRIO</span>
              <h4 className="mt-1">R$ <span id="textProductPrice">{unitPrice}</span></h4>
            </div>
            <div className="col-3">
              <span className="text-info">PESO (KG)</span>
              <h4 className="mt-1"><span id="textProductPrice">{weight}</span>  KG</h4>
            </div>

          </>
        </div>
      </Card.Body >
      <Card.Footer>
        <div className="d-flex justify-content-between">
          <Button size="md" variant="info">
            <FontAwesomeIcon icon={faEraser} className="me-3" /> Limpar Venda</Button>
          <Form onSubmit={handleSubmit}>
            <Button size="md" variant="success" type='submit'>
              <FontAwesomeIcon icon={faCheck} className="me-3" /> Cadastrar Dados</Button>
          </Form>

        </div>
      </Card.Footer>
    </Card >
  );
};

export const ProductTableWidget = ({ paymentData, setCartArray, cartArray, response }) => {
  let discount = paymentData.discount;

  const handleDelete = (index) => {
    const updatedCartArray = [...cartArray];
    updatedCartArray.splice(index, 1);
    setCartArray(updatedCartArray);
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-2 h-100">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <span>PRODUTOS NO CARRINHO</span>
        </div>
      </Card.Header>
      <Card.Body className="p-0 table-responsive" style={{ height: '1px' }}>
        <Table hover className="align-items-center table-head-fixed" >
          <thead>
            <tr>
              <th>#</th>
              <th>COD. BARRAS</th>
              <th>QTD UN</th>
              <th>PRODUTO</th>
              <th>VALOR TOTAL</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {cartArray.length > 0 &&

              cartArray.map((product, index) => (
                <tr key={index}>
                  <td>{cartArray.length - index}</td>
                  <td>{product.barcode}</td>
                  <td><br />{product.amount} UN {product.weight > 0 && `/ ${product.weight} KG`}</td>
                  <td className="text-uppercase">{product.product}<br />x R$ {product.price} </td>
                  <td>R$ {product.total_price}</td>
                  <td>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-between">
          <div className="col-4">
            <div className="col">
              <span className="text-info">Quantidade: </span><span className="text-black" id="CartAmount">{cartArray.length}</span>
            </div>
            <div className="col">
              <span className="text-info">Subtotal: </span> <span className="text-black" id="CartPrice">{paymentData.price ? paymentData.price.subtotal.toFixed(2) : '0.00'}</span>
            </div>
            <div className="col">
              <span className="text-info">Desconto: </span> <span className="text-black" id="CartDiscount">{discount}</span>%
            </div>
          </div>
          <div className="col-3 text-right">
            <span className="text-info">Total</span>
            <h3>R$ <span id="cartTotalPrice">{paymentData.price ? paymentData.price.total.toFixed(2) : '0.00'}</span></h3>
          </div>
        </div>

      </Card.Footer>
    </Card >
  );
};

export const PaymentMethodWidget = ({ setPaymentData, cartArray, error, response }) => {
  const [paymentId, setPaymentId] = useState("");
  const [paymentInput, setPaymentInput] = useState("")
  const [paidAmount, setPaidAmount] = useState("");
  const [discount, setDiscount] = useState("0");
  const [change, setChange] = useState("0");
  const [isDisabled, setDisabled] = useState(false);
  const { data: payments, isLoading: paymentLoading } = FetchPayment();

  let total, subtotal;
  subtotal = (cartArray.reduce((a, v) => a = a + parseFloat(v.total_price), 0));
  total = (subtotal - (subtotal * parseFloat(discount) / 100));

  useEffect(() => {
    if (paymentId !== "" && (paymentId !== 5 && paymentId !== 3)) { setPaidAmount(total.toString()); setChange("0"); setDisabled(true) }
    else {
      setDisabled(false)
    };
    if (discount === "") setDiscount("0");
    if (discount && change) {
      setPaymentData({ paymentId, discount, change, paidAmount, price: { subtotal, total } });
    }
  }, [cartArray, paymentId, discount, change]);

  useEffect(() => {
    let tempPaidAmount;
    tempPaidAmount = paidAmount.replace(",", ".");
    if (!paidAmount) tempPaidAmount = 0.00;

    if (total > 0) setChange((Math.max(0, (parseFloat(tempPaidAmount) - total)).toFixed(2)).toString());
  }, [paidAmount])

  const ref = React.createRef();

  useEffect(() => {
    if (response) {
      setDiscount("0");
      setPaymentId("");
      setPaidAmount("0")
      ref.current.clear();
    }

  }, [response])


  return (
    <Card border="light" className="bg-white shadow-sm mb-2">
      <Card.Header>
        <div className="d-flex">
          <span>FORMA DE PAGAMENTO <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
            <Tooltip style={{ margin: 0 }}>Para registrar o pagamento, basta preencher apenas o campo do meio com o valor pago.</Tooltip>}>
            <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
          </OverlayTrigger></span>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex  mb-4">
          <span className="icon icon-md">
            <FontAwesomeIcon icon={faLandmark} className="me-3" />
          </span>
          <Form.Group className="mb-2 col">
            <Typeahead
              ref={ref}
              id="payments"
              className="is-invalid"
              labelKey={payments => `${payments.name}`}
              onChange={value => value[0] && setPaymentId(value[0].id)}
              options={payments}
              placeholder="Selecione uma Forma de Pagamento"
              isLoading={paymentLoading}
            />
            <Form.Control.Feedback type="invalid">{error.paymentMethod}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex justify-content-between">
          <span className="icon icon-md">
            <FontAwesomeIcon icon={faPercent} className="me-3" />
          </span>
          <Form.Group className="col-2">
            <Form.Control type="text" as={IMaskInput} mask={Number} max={100} value={discount} onChange={e => setDiscount(e.target.value)} placeholder="Desconto" />
          </Form.Group>
          <span className="icon icon-md">
            <FontAwesomeIcon icon={faCoins} className="ms-3 me-3" />
          </span>
          <Form.Group>
            <Form.Control disabled={isDisabled} type="text" as={IMaskInput} mask={Number} value={paidAmount} onChange={e => setPaidAmount(e.target.value)} placeholder="Valor Pago" isInvalid={!!error.paidAmount} />
            <Form.Control.Feedback type="invalid">{error.paidAmount}</Form.Control.Feedback>

          </Form.Group>
          <span className="icon icon-md">
            <FontAwesomeIcon icon={faCashRegister} className="ms-3 me-3" />
          </span>
          <Form.Group>
            <Form.Control disabled type="text" value={change} onChange={e => setChange(e.target.value)} placeholder="Troco" />
          </Form.Group>
        </div>
      </Card.Body>
    </Card>
  );
};

export const PaymentWidget = (props) => {
  const { icon, iconColor, name, tax, set_status, timesUsed, id, refreshState, setRefreshState } = props;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={4} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={'rounded me-4 me-sm-0'} style={{ color: iconColor, backgroundColor: '#f0f0f5' }}>
              <FontAwesomeIcon className="fa-5x" icon={icon} />
            </div>
          </Col>
          <Col xs={12} xl={6} className="px-xl-0">
            <h3 className="mb-1 text-truncate">{name}</h3>
            <h5 className="mb-1">{tax}%</h5>
            <h5 className="mb-1">{set_status}</h5>
            <small><FontAwesomeIcon icon={faCoins} size="xs" /> {timesUsed} Vezes Utilizada</small>
          </Col>
          <Col xl={2} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <Dropdown as={ButtonGroup} >
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <PaymentDetailModal id={id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeletePaymentModal id={id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const RoleWidget = (props) => {
  const { icon, iconColor, rolename, salary, users = 0, id, refreshState, setRefreshState } = props;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={4} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={`icon-${iconColor} rounded me-4 me-sm-0`}>
              <FontAwesomeIcon className="fa-5x" icon={icon} />
            </div>
          </Col>
          <Col xs={12} xl={6} className="px-xl-0">
            <h3 className="mb-1 text-truncate">{rolename}</h3>
            <h5 className="mb-1">R$ {salary}</h5>
            <small><FontAwesomeIcon icon={faUsers} size="xs" /> {users} Funcionário(s)</small>
          </Col>
          <Col xl={2} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <Dropdown as={ButtonGroup} >
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <RoleDetailModal id={id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeleteRoleModal id={id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const CategoryWidget = (props) => {
  const { icon, iconColor, name, id, refreshState, setRefreshState } = props;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={4} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <div className={'rounded me-4 me-sm-0'} style={{ color: iconColor, backgroundColor: '#f0f0f5' }}>
              <FontAwesomeIcon className="fa-5x" icon={icon} />
            </div>
          </Col>
          <Col xs={12} xl={6} className="px-xl-0">
            <h3 className="mb-1 text-truncate">{name}</h3>
          </Col>
          <Col xl={2} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <Dropdown as={ButtonGroup} >
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <CategoryDetailModal id={id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeleteCategoryModal id={id} refreshState={refreshState} setRefreshState={setRefreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const GainsWidget = (props) => {
  const { color, title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Row className="g-0" >
      <Col xl={12} className="px-xl-0">
        <div className={`h4 mb-1 fw-bolder ${color}`}>R$ {value}</div>
        <div className="grey me-1">{title}</div>
      </Col>
    </Row>
  );
};

export const FinancialHeaderWidget = (props) => {
  const { data, stroke, title, reverse, iconColor, color, value, period, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  let percentageColor = percentage < 0 ? "text-danger" : "text-success";

  if (reverse)
    percentageColor = percentage < 0 ? "text-success" : "text-danger";

  return (
    <Row className="d-block d-xl-flex align-items-center ms-2">
      <Col xl={12} className="px-xl-0">
        <div><span className="h3 me-2">{title}</span>  <span className={`${percentageColor} fw-bold`}> <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor}`} />  {percentage}%</span> </div>

        <div className={`h3 mb-1 fw-bolder d-md-inline ${color}`}>R$ {value} </div>
        <div className="grey">{period}</div>
      </Col>
      <Col xl={12} className="p-0">
        <Container className="p-0" style={{ minWidth: "100%", height: "120px" }}>
          <FinancialHeaderChart stroke={stroke} data={data} />
        </Container>
      </Col>
    </Row>
  );
};

export const WeekDaysEarningsWidget = (props) => {
  const { data, fill, title, icon, iconColor, color, category, value, period, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Row className="d-block d-xl-flex align-items-center ms-2">
      <Col xl={12} className="px-xl-0">
        <h3>{title}</h3>
        <div className="d-flex align-items-center">
          <div className={`h3 mb-1 fw-bolder ${color}`}>{value} </div>

        </div>

      </Col>
      <Col xl={12} className="p-0">
        <Container className="p-0" style={{ minWidth: "100%", height: "350px" }}>
          <WeekdaysEarningsChart fill={fill} data={data} />
        </Container>
      </Col>

    </Row>
  );
};

export const CircleChartWidget = (props) => {
  const { title, data = [] } = props;
  const series = data.map(d => d.value);

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            {/* <CircleChart series={series} /> */}
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <h5 className="mb-3">{title}</h5>

            {data.map(d => (
              <h6 key={`circle-element-${d.id}`} className="fw-normal text-gray">
                <FontAwesomeIcon icon={d.icon} className={`icon icon-xs text-${d.color} w-20 me-1`} />
                {` ${d.label} `}{`${d.value}%`}
              </h6>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const BarChartWidget = (props) => {
  // const { title, value, percentage, data = [] } = props;
  // const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // const series = data.map(d => d.value);
  // const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  // const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  // return (
  //   <Card border="light" className="shadow-sm">
  //     <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
  //       <div className="d-block">
  //         <h6 className="fw-normal text-gray mb-2">{title}</h6>
  //         <h3>{value}</h3>
  //         <small className="mt-2">
  //           <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
  //           <span className={`${percentageColor} fw-bold`}>
  //             {percentage}%
  //           </span>
  //         </small>
  //       </div>

  //       {/* <div className="d-block ms-auto">
  //         {data.map(d => (
  //           <div key={`bar-element-${d.id}`} className="d-flex align-items-center text-end mb-2">
  //             <span className={`shape-xs rounded-circle bg-${d.color} me-2`} />
  //             <small className="fw-normal">{d.label}</small>
  //           </div>
  //         ))}
  //       </div> */}
  //       <div className="d-flex ms-auto">
  //         <Button variant="primary" size="sm" className="me-2">Week</Button>
  //         <Button variant="secondary" size="sm" className="me-2">Month</Button>
  //         <Button variant="primary" className="me-2"> <FontAwesomeIcon icon={faCalendarAlt} /></Button>

  //       </div>
  //     </Card.Body>
  //     <Card.Body className="p-2">
  //       <BarChart labels={labels} series={series} />
  //     </Card.Body>
  //   </Card>
  // );
};

export const TeamMembersWidget = () => {
  const TeamMember = (props) => {
    const { name, statusKey, image, icon, btnText } = props;
    const status = {
      online: { color: "success", label: "Online" },
      inMeeting: { color: "warning", label: "In a meeting" },
      offline: { color: "danger", label: "Offline" }
    };

    const statusColor = status[statusKey] ? status[statusKey].color : 'danger'
      , statusLabel = status[statusKey] ? status[statusKey].label : 'Offline';

    return (
      <ListGroup.Item className="px-0">
        <Row className="align-items-center">
          <Col className="col-auto">
            <a href="#top" className="user-avatar">
              <Image src={image} className="rounded-circle" />
            </a>
          </Col>
          <Col className="ms--2">
            <h4 className="h6 mb-0">
              <a href="#!">{name}</a>
            </h4>
            <span className={`text-${statusColor}`}>● </span>
            <small>{statusLabel}</small>
          </Col>
          <Col className="col-auto">
            <Button variant="tertiary" size="sm">
              <FontAwesomeIcon icon={icon} className="me-1" /> {btnText}
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">Team members</h5>
        <Button variant="secondary" size="sm">See all</Button>
      </Card.Header>
      <Card.Body>
        <ListGroup className="list-group-flush list my--3">
          {teamMembers.map(tm => <TeamMember key={`team-member-${tm.id}`} {...tm} />)}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export const ProgressTrackWidget = () => {
  const Progress = (props) => {
    const { title, percentage, icon, color, last = false } = props;
    const extraClassName = last ? "" : "mb-2";

    return (
      <Row className={`align-items-center ${extraClassName}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <FontAwesomeIcon icon={icon} className="me-1" />
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{title}</h6>
              <small className="fw-bold text-dark">
                <span>{percentage} %</span>
              </small>
            </div>
            <ProgressBar variant={color} now={percentage} min={0} max={100} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light">
        <h5 className="mb-0">Progress track</h5>
      </Card.Header>
      <Card.Body>

        <Progress title="Rocket - SaaS Template" color="purple" icon={faBootstrap} percentage={34} />
        <Progress title="Pixel - Design System" color="danger" icon={faAngular} percentage={60} />
        <Progress title="Spaces - Listings Template" color="tertiary" icon={faVuejs} percentage={45} />
        <Progress title="Stellar - Dashboard" color="info" icon={faReact} percentage={35} />
        <Progress last title="Volt - Dashboard" color="purple" icon={faBootstrap} percentage={34} />
      </Card.Body>
    </Card>
  );
};

export const RankingWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
          <div>
            <h6><FontAwesomeIcon icon={faGlobeEurope} className="icon icon-xs me-3" /> Global Rank</h6>
          </div>
          <div>
            <Card.Link href="#" className="text-primary fw-bold">
              #755 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFlagUsa} className="icon icon-xs me-3" />Country Rank</h6>
            <div className="small card-stats">
              United States <FontAwesomeIcon icon={faAngleUp} className="icon icon-xs text-success ms-2" />
            </div>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #32 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFolderOpen} className="icon icon-xs me-3" />Category Rank</h6>
            <Card.Link href="#top" className="small card-stats">
              Travel &gt; Accomodation
            </Card.Link>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #16 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const SalesValueWidget = (props) => {
  const { title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <div className="bg-secondary-alt shadow-sm">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-2">Week</Button>
          <Button variant="primary" className="me-2"> <FontAwesomeIcon icon={faCalendarAlt} /></Button>

        </div>
      </Card.Header>
      <Card.Body className="p-2">
        {/* <SalesValueChart /> */}
      </Card.Body>
    </div>
  );
};

export const SalesValueWidgetPhone = (props) => {
  const { title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card className="bg-secondary-alt shadow-sm">
      <Card.Header className="d-md-flex flex-row align-items-center flex-0">
        <div className="d-block mb-3 mb-md-0">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-3">Week</Button>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        {/* <SalesValueChartphone /> */}
      </Card.Body>
    </Card>
  );
};

export const AcquisitionWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <h5>Acquisition</h5>
        <p>Tells you where your visitors originated from, such as search engines, social networks or website referrals.</p>
        <div className="d-block">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Bounce Rate</label>
              <h4 className="mb-0">33.50%</h4>
            </div>
          </div>
          <div className="d-flex align-items-center pt-3">
            <div className="icon icon-shape icon-sm icon-shape-quaternary rounded me-3">
              <FontAwesomeIcon icon={faChartArea} />
            </div>
            <div className="d-block">
              <label className="mb-0">Sessions</label>
              <h4 className="mb-0">9,567</h4>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
