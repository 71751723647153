import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { IMaskInput } from "react-imask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCoins } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Nav,
  Tab,
  Spinner,
} from "react-bootstrap";
import { CreateCustomer } from "../services/CustomerService";
import { toast } from "react-toastify";
import { FetchSuppliers } from "../services/SupplierService";
import { CreateProduct } from "../services/ProductService";
import { FetchCategories } from "../services/CategoryService";
import { InputGroup } from "react-bootstrap";
import { CreateSupplier } from "../services/SupplierService";
import { Typeahead } from "react-bootstrap-typeahead";
import { CreateBill } from "../services/BillService";

export const BillForm = () => {
  const [supplierId, setSupplierId] = useState("");
  const [parcels, setParcels] = useState("");
  const [description, setDescription] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const { data: suppliers, isLoading: suppliersLoading } = FetchSuppliers();
  const [currentTab, setCurrentTab] = useState("bill");
  const [dueDate, setDueDate] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [totalValue, setTotalValue] = useState("");

  const [error, setError] = useState({});
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const addFields = () => {
    let newfield = [];
    var amount = totalValue / parcels;
    for (let i = 0; i < parcels; i++) {
      let newDueDate = dueDate.clone().add(i, "months");
      newfield[i] = { barcode: "", amount: amount, dueDate: newDueDate };
    }

    setInputFields(newfield);
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    if (event.target) {
      data[index][event.target.name] = event.target.value;
    } else {
      data[index]["dueDate"] = event;
    }
    setInputFields(data);
  };

  let handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const response = await CreateBill(
        supplierId,
        totalValue,
        description,
        inputFields
      );
      if (response.data.status === "success") {
        toast.success("Boleto cadastrado com sucesso!");
        setSupplierId(undefined);
        setParcels("");
        setDescription("");
        setDisabled(true);
        setCurrentTab("bill");
        setDueDate("");
        setInputFields([]);
        setTotalValue("");
        setError("");
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao cadastrar o boleto!");
      }
    }
  };

  const handleNext = async (event) => {
    if (supplierId && parcels && dueDate && totalValue) {
      addFields();
      setError({});
      setIsGenerating(true);
      await delay(1000);
      setIsGenerating(false);
      setDisabled(false);
      setCurrentTab("parcels");
    } else {
      const TempError = {};
      if (!supplierId) {
        TempError.supplierId = [];
        TempError.supplierId[0] = "O Campo fornecedor não pode estar vazio!";
      }
      if (!parcels) {
        TempError.parcels = [];
        TempError.parcels[0] = "O Campo parcelas não pode estar vazio!";
      }
      if (!totalValue) {
        TempError.totalValue = [];
        TempError.totalValue[0] = "O Campo valor total não pode estar vazio!";
      }
      if (!dueDate) {
        TempError.dueDate = [];
        TempError.dueDate[0] =
          "O Campo data de vencimento não pode estar vazio!";
      }
      setError(TempError);
      toast.warn("Verifique os campos obrigatórios!");
    }
  };

  return (
    <Tab.Container
      defaultActiveKey="bill"
      activeKey={currentTab}
      onSelect={(key) => setCurrentTab(key)}
    >
      <Row>
        <Col lg={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Col lg={12}>
              <Nav className="nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bill" className="mb-sm-3 mb-md-0">
                    Dados do Boleto
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="parcels"
                    className="mb-sm-3 mb-md-0"
                    disabled={isDisabled}
                  >
                    Parcelas Geradas
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="bill" className="py-2">
                  <h5 className="mb-4">Dados do Emitente</h5>
                  <Form>
                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Group>
                          <Form.Label>Empresa</Form.Label>
                          <Typeahead
                            id="supplier"
                            className="is-invalid"
                            labelKey={(suppliers) => `${suppliers.name}`}
                            onChange={(value) =>
                              value[0] && setSupplierId(value[0].id)
                            }
                            options={suppliers}
                            placeholder="Selecione um Fornecedor"
                            isLoading={suppliersLoading}
                            isInvalid={!!error.supplierId}
                          />

                          <Form.Control.Feedback type="invalid">
                            {error.supplierId}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>
                            Descrição <small>(Opcional)</small>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Insira um Descrição"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <h5 className="my-4">Dados do Boleto</h5>
                    <Row>
                      <Col sm={4} className="mb-3">
                        <Form.Group>
                          <Form.Label className="me-1">
                            Quantidade de Parcelas
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="0"
                            isInvalid={!!error.parcels}
                            value={parcels}
                            onChange={(e) => setParcels(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error.parcels}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>Valor Total</Form.Label>
                          <InputGroup hasValidation>
                            <InputGroup.Text
                              className={!!error.totalValue && "is-invalid"}
                            >
                              <FontAwesomeIcon icon={faCoins} />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="R$ 00.00"
                              value={totalValue}
                              onChange={(e) => setTotalValue(e.target.value)}
                              isInvalid={!!error.totalValue}
                            />
                            <Form.Control.Feedback type="invalid">
                              {error.totalValue}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>Vencimento 1º Parcela</Form.Label>
                          <Datetime
                            timeFormat={false}
                            closeOnSelect={false}
                            onChange={setDueDate}
                            renderInput={(props, openCalendar) => (
                              <InputGroup hasValidation>
                                <InputGroup.Text
                                  className={!!error.dueDate && "is-invalid"}
                                >
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </InputGroup.Text>
                                <Form.Control
                                  isInvalid={!!error.dueDate}
                                  type="text"
                                  value={
                                    dueDate
                                      ? moment(dueDate).format("DD/MM/YYYY")
                                      : ""
                                  }
                                  placeholder="dd/mm/yyyy"
                                  onFocus={openCalendar}
                                  onChange={() => { }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error.dueDate}
                                </Form.Control.Feedback>
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="d-flex mt-3 justify-content-end align-middle">
                      {isGenerating ? (
                        <>
                          <Spinner
                            animation="border"
                            role="status"
                            className="me-3"
                          />
                          <Button variant="primary" disabled>
                            Gerando Parcelas...
                          </Button>
                        </>
                      ) : (
                        <Button variant="primary" onClick={handleNext}>
                          Gerar Parcelas
                        </Button>
                      )}
                    </div>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="parcels" className="py-2">
                  <Form onSubmit={handleSubmit}>
                    <h5 className="mb-4">Dados das Parcelas</h5>
                    {inputFields &&
                      inputFields.map((input, index) => {
                        return (
                          <Row key={index}>
                            <Col sm={4}>
                              <Form.Group>
                                <Form.Label className="me-1">
                                  Cód. Barras <small>(Opcional)</small>
                                </Form.Label>
                                <Form.Control
                                  name="barcode"
                                  type="text"
                                  placeholder="000 0 00000 000000 0000 0000000000  000000000000000

                                  "
                                  value={input.barcode}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <Form.Label>Valor</Form.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <FontAwesomeIcon icon={faCoins} />
                                  </InputGroup.Text>
                                  <Form.Control
                                    name="amount"
                                    type="text"
                                    placeholder="R$ 00.00"
                                    value={input.amount}
                                    onChange={(event) =>
                                      handleFormChange(index, event)
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group>
                                <Form.Label>Vencimento</Form.Label>
                                <Datetime
                                  timeFormat={false}
                                  closeOnSelect={false}
                                  name="dueDate"
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                  renderInput={(props, openCalendar) => (
                                    <InputGroup>
                                      <InputGroup.Text>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                      </InputGroup.Text>
                                      <Form.Control
                                        name="dueDate"
                                        type="text"
                                        value={
                                          input.dueDate
                                            ? moment(input.dueDate).format(
                                              "DD/MM/YYYY"
                                            )
                                            : ""
                                        }
                                        placeholder="dd/mm/yyyy"
                                        onFocus={openCalendar}
                                        onChange={() => { }}
                                      />
                                    </InputGroup>
                                  )}
                                />
                              </Form.Group>
                            </Col>
                            <hr className="bg-gray my-3" />
                          </Row>
                        );
                      })}

                    <div className="d-flex mt-3 justify-content-end">
                      <Button variant="primary" type="submit">
                        Cadastrar Dados
                      </Button>
                    </div>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export const ProductForm = () => {
  const [supplierId, setSupplierId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [amount, setAmount] = useState(1);
  const [productCode, setProductCode] = useState("");
  const [name, setName] = useState("");
  const [weight, setWeight] = useState(0);
  const [buyCost, setBuyCost] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [barcode, setBarcode] = useState("");

  const { data: suppliers, isLoading: suppliersLoading } = FetchSuppliers();
  const { data: categories, isLoading: categoryLoading } = FetchCategories();

  const [error, setError] = useState({});

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreateProduct(
        supplierId,
        categoryId,
        amount,
        barcode,
        productCode,
        name,
        weight,
        buyCost,
        sellPrice
      );
      if (response.data.status === "success") {
        setSupplierId("");
        setCategoryId("");
        setAmount(1);
        setProductCode("");
        setName("");
        setWeight(1);
        setBuyCost("");
        setSellPrice("");
        setBarcode("");

        toast.success("Produto cadastrado com sucesso!");
        setError("");
      } else {
        throw new Error("Falha ao cadastrar o produto");
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao cadastrar o produto!");
      }
    }
  };
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Dados do Fornecimento</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Fornecedor</Form.Label>
                <Typeahead
                  id="supplier"
                  className="is-invalid"
                  labelKey={(suppliers) => `${suppliers.name}`}
                  onChange={(value) => value[0] && setSupplierId(value[0].id)}
                  options={suppliers}
                  placeholder="Selecione um Fornecedor"
                  isInvalid={!!error.supplierId}
                  isLoading={suppliersLoading}
                />

                <Form.Control.Feedback type="invalid">
                  {error.supplierId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Categoria</Form.Label>
                <Typeahead
                  id="category"
                  className="is-invalid"
                  labelKey={(categories) => `${categories.name}`}
                  onChange={(value) => value[0] && setCategoryId(value[0].id)}
                  options={categories}
                  placeholder="Selecione uma Categoria"
                  isInvalid={!!error.categoryId}
                  isLoading={categoryLoading}
                />
                <Form.Control.Feedback type="invalid">
                  {error.categoryId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  isInvalid={!!error.amount}
                />
                <Form.Control.Feedback type="invalid">
                  {error.amount}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Dados do Produto</h5>
          <Row>
            <Col sm={6} xl={3} className="mb-3">
              <Form.Group>
                <Form.Label className="me-1">Cód. Barras</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0000000000000"
                  as={IMaskInput}
                  mask="0000000000000"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  isInvalid={!!error.barcode}
                />
                <Form.Control.Feedback type="invalid">
                  {error.barcode}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col col={4}>
              <Form.Group>
                <Form.Label>
                  Cód. Produto <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                  isInvalid={!!error.product_code}
                />
                <Form.Control.Feedback type="invalid">
                  {error.product_code}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Produto Exemplo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type="invalid">
                  {error.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} xl={6} className="mb-3">
              <Form.Group>
                <Form.Label className="required">Peso (KG)</Form.Label>
                <Form.Control
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  isInvalid={!!error.weight}
                />
                <Form.Control.Feedback type="invalid">
                  {error.weight}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} xl={3} className="mb-3">
              <Form.Group>
                <Form.Label>
                  Custo de Compra <small>(Opcional)</small>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="R$ 00.00"
                    value={buyCost}
                    onChange={(e) => setBuyCost(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={6} xl={3}>
              <Form.Group>
                <Form.Label>Preço de Venda</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text
                    className={!!error.sell_price && "is-invalid"}
                  >
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="R$ 00.00"
                    value={sellPrice}
                    onChange={(e) => setSellPrice(e.target.value)}
                    isInvalid={!!error.sell_price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.sell_price}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex mt-3 justify-content-end">
            <Button variant="primary" type="submit">
              Cadastrar Dados
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const CustomerForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [error, setError] = useState({});

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreateCustomer(
        name,
        phone,
        street,
        district,
        houseNumber
      );
      if (response.data.status === "success") {
        setName("");
        setPhone("");
        setStreet("");
        setDistrict("");
        setHouseNumber("");
        toast.success("Cliente cadastrado com sucesso!");
        setError("");
      } else {
        throw new Error("Falha ao cadastrar cliente");
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao cadastrar o cliente!");
      }
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Dados do Cliente</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} xl={8} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>Nome Completo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome Exemplo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type="invalid">
                  {error.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={12} xl={4} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Telefone <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  as={IMaskInput}
                  mask="(00) 00000-0000"
                  placeholder="(00) 00000-0000"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} xl={3} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Rua <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Rua Exemplo"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {error.street}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4} xl={3} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Bairro <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Bairro Exemplo"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={4} xl={2} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Número <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="000"
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex mt-3 justify-content-end">
            <Button variant="primary" type="submit">
              Cadastrar Dados
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const SupplierForm = () => {
  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [CNPJ, setCNPJ] = useState("");
  const [minOrder, setMinOrder] = useState("");
  const [seller, setSeller] = useState("");
  const [sellerPhone, setSellerPhone] = useState("");

  const { data: categories, isLoading: categoryLoading } = FetchCategories();
  const [error, setError] = useState({});

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await CreateSupplier(
        name,
        categoryId,
        CNPJ,
        minOrder,
        seller,
        sellerPhone
      );

      if (response.data.status === "success") {
        setName("");
        setCategoryId("");
        setCNPJ("");
        setMinOrder("");
        setSeller("");
        setSellerPhone("");
        toast.success("Fornecedor cadastrado com sucesso!");
        setError("");
      } else {
        throw new Error("Falha ao cadastrar o produto");
      }
    } catch (err) {
      if (err.response.data.errors) {
        setError(err.response.data.errors);
        toast.warn("Verifique os campos obrigatórios!");
      } else {
        toast.error("Ocorreu um erro ao cadastrar o fornecedor!");
      }
    }
  };
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Dados do Fornecedor</h5>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>Nome da Empresa</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Empresa Exemplo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!error.name}
                />
                <Form.Control.Feedback type="invalid">
                  {error.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Categoria</Form.Label>
                <Typeahead
                  id="category_id"
                  className="is-invalid"
                  labelKey={(categories) => `${categories.name}`}
                  onChange={(value) => value[0] && setCategoryId(value[0].id)}
                  options={categories}
                  placeholder="Selecione uma Categoria"
                  isInvalid={!!error.categoryId}
                  isLoading={categoryLoading}
                />
                <Form.Control.Feedback type="invalid">
                  {error.categoryId}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  CNPJ <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="XX.XXX.XXX/0001-XX"
                  value={CNPJ}
                  onChange={(e) => setCNPJ(e.target.value)}
                  isInvalid={!!error.CNPJ}
                />
                <Form.Control.Feedback type="invalid">
                  {error.CNPJ}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="storage">
                <Form.Label>
                  Pedido Mínimo <small>(Opcional)</small>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCoins} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="R$ 00.00"
                    value={minOrder}
                    onChange={(e) => setMinOrder(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Dados do Vendedor</h5>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="seller">
                <Form.Label className="me-1">
                  Nome <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome Exemplo"
                  value={seller}
                  onChange={(e) => setSeller(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group id="phone_number">
                <Form.Label>
                  Telefone <small>(Opcional)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  as={IMaskInput}
                  mask="(00) 00000-0000"
                  placeholder="(00) 00000-0000"
                  value={sellerPhone}
                  onChange={(e) => setSellerPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex mt-3 justify-content-end">
            <Button variant="primary" type="submit">
              Cadastrar Dados
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};