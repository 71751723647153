
import React, { useEffect, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faHome, faShoppingBag, faUsers, faBoxes, faUserShield, faLandmark, faChartArea, faQrcode, faWrench, faCartPlus, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AppRoutes } from "../routes";
import ReactHero from "../assets/img/technologies/cdr-logo-primary.png";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (

      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };


  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={AppRoutes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-3 pt-3">
            <Nav className="flex-column pt-3 pt-md-0">
              <Navbar.Brand className="ms-3 me-lg-5 mr-5 py-2" as={Link} to={AppRoutes.DashboardOverview.path}>
                <Image src={ReactHero} className="navbar-brand-light" />cdr_JardimSul
              </Navbar.Brand>
              <Dropdown.Divider className="border-indigo" />
              <Button as={Link} to={AppRoutes.CreateSale.path} variant="secondary" className="create-new-sale"><FontAwesomeIcon icon={faCartPlus} className="me-1" /> Cadastrar nova venda</Button>
              <Dropdown.Divider className="my-3 border-indigo" />
              <NavItem title="Home" link={AppRoutes.DashboardOverview.path} icon={faHome} />
              <Dropdown.Header>PRODUÇÃO</Dropdown.Header>
              <CollapsableNavItem title="Produtos" icon={faShoppingBag}>
                <NavItem title="Cadastrar" link={AppRoutes.CreateProduct.path} icon={faPlus} />
                <NavItem title="Consultar" link={AppRoutes.ViewProduct.path} icon={faEye} />
              </CollapsableNavItem>
              <Dropdown.Header>RECURSOS HUMANOS</Dropdown.Header>
              <CollapsableNavItem eventKey="examples/" title="Fornecedores" icon={faBoxes}>
                <NavItem title="Cadastrar" link={AppRoutes.CreateSupplier.path} icon={faPlus} />
                <NavItem title="Consultar" link={AppRoutes.ViewSupplier.path} icon={faEye} />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="examples/" title="Clientes" icon={faUsers}>
                <NavItem title="Cadastrar" link={AppRoutes.CreateCustomer.path} icon={faPlus} />
                <NavItem title="Consultar" link={AppRoutes.ViewCustomer.path} icon={faEye} />
              </CollapsableNavItem>
              <NavItem title="Funcionários" link={AppRoutes.ViewWorker.path} icon={faUserShield} />

              <Dropdown.Header>FINANCEIRO</Dropdown.Header>
              <NavItem title="Resumo de Vendas" link={AppRoutes.ViewSales.path} icon={faLandmark} />
              <CollapsableNavItem eventKey="examples/" title="Relatórios" icon={faChartArea}>
                <NavItem title="Financeiro" link={AppRoutes.ViewReport.path} icon={faChartLine} />
                {/* <NavItem title="Estatística" link={AppRoutes.ViewStatistic.path} icon={faChartBar} /> */}
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="examples/" title="Boletos" icon={faQrcode}>
                <NavItem title="Cadastrar" link={AppRoutes.CreateBill.path} icon={faEye} />
                <NavItem title="Consultar" link={AppRoutes.ViewBill.path} icon={faPlus} />
              </CollapsableNavItem>
              <Dropdown.Header>ADMINISTRATIVO</Dropdown.Header>
              <CollapsableNavItem eventKey="examples/" title="Configurações" icon={faWrench}>
                <NavItem title="Pagamentos & Taxas" link={AppRoutes.ViewPayment.path} />
                <NavItem title="Cargos & Permissões" link={AppRoutes.ViewRole.path} />
                <NavItem title="Categorias" link={AppRoutes.ViewCategory.path} />
              </CollapsableNavItem>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

