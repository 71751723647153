import React, { useEffect, useState } from 'react';
import { Row, Col, InputGroup, Form, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faFilter, faPlus, faQuestionCircle, faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import 'moment/locale/pt-br';
import { toast } from 'react-toastify';
import { CreateUser, FetchUsers } from '../services/UserService';
import { CreateRole, FetchAbilities, FetchRoles } from '../services/RoleService';
import { CreatePayment, FetchPayment } from '../services/PaymentService';
import { CreateCategory, FetchCategories } from '../services/CategoryService';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FetchSuppliers } from '../services/SupplierService';
import { FetchStatus } from '../services/SaleService';
import { FetchCustomers } from '../services/CustomerService';

export const CategoryFilter = ({ setFilter, refreshState, setRefreshState }) => {
    const [search, setSearch] = useState("");
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
    const [name, setName] = useState("");
    const [error, setError] = useState({});

    useEffect(() => {
        handleFilter();
    }, [search]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
    };

    let handleSubmit = async (e) => {
        if (e) e.preventDefault();
        try {
            const response = await CreateCategory(name);
            if (response.data.status === 'success') {
                setName("");
                toast.success('Categoria cadastrada com sucesso!')
                refreshState == true ? setRefreshState(false) : setRefreshState(true);
                handleClose();
                setError("");
            } else {
                throw new Error('Falha ao cadastrar categoria');
            }
        } catch (err) {

            if (err.response.data.errors) {
                setError(err.response.data.errors);
                toast.warn('Verifique os campos obrigatórios!');

            } else {
                toast.error('Ocorreu um erro ao cadastrar a categoria!');

            };
        }
    };


    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={4} md={2} xl={2} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="success" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faPlus} /> Cadastrar Categoria</Button>

                        <Modal size={'md'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Cadastro de Categoria</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>

                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Dados da Categoria</h5>
                                        <Col md={12} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control type="text" placeholder='Nome Exemplo' value={name} onChange={e => setName(e.target.value)} isInvalid={!!error.name} />
                                                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Cadastrar Dados
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )

};

export const PaymentFilter = ({ setFilter, refreshState, setRefreshState}) => {
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
    const [search, setSearch] = useState("");
    const [name, setName] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [color, setColor] = useState('#ffffff');
    const [status, setStatus] = useState(2);
    const [tax, setTax] = useState(0);
    const [error, setError] = useState({});

    useEffect(() => {
        handleFilter();
    }, [search]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
        handleClose();
    };

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await CreatePayment(name, backgroundColor, color, status, tax);
            if (response.data.status === 'success') {
                refreshState == true ? setRefreshState(false) : setRefreshState(true);
                setName("");
                setBackgroundColor("#000000");
                setColor("#000000");
                setStatus(2);
                setTax(0);
                toast.success('Método cadastrado com sucesso!')
                handleClose();
                setError("");
            } else {
                throw new Error('Falha ao cadastrar método');
            }
        } catch (err) {

            if (err.response.data.errors) {
                setError(err.response.data.errors);
                toast.warn('Verifique os campos obrigatórios!');

            } else {
                toast.error('Ocorreu um erro ao cadastrar o método!');

            };
        }
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={4} md={2} xl={2} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="success" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faPlus} /> Cadastrar Pagamento</Button>

                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Cadastro de Métd. Pagamento</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Dados do Métd. Pagamento</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control type="text" placeholder='Nome Exemplo' value={name} onChange={e => setName(e.target.value)} isInvalid={!!error.name} />
                                                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Taxa</Form.Label>
                                                <Form.Control type="number" placeholder='0%' value={tax} onChange={e => setTax(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group>

                                                <Form.Label>Definir status de venda:</Form.Label>
                                                <div>
                                                    <Form.Check
                                                        inline
                                                        label="Em Aberto"
                                                        type={"radio"}
                                                        value='1' onChange={e => setStatus(1)} />
                                                    <Form.Check
                                                        inline
                                                        label="Concluida"
                                                        type={"radio"}
                                                        value='2' onChange={e => setStatus(2)} />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Cor do texto</Form.Label>
                                                <Form.Control type="color" value={color} onChange={e => setColor(e.target.value)} placeholder='Nome Exemplo' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Cor de Fundo</Form.Label>
                                                <Form.Control type="color" value={backgroundColor} onChange={e => setBackgroundColor(e.target.value)} isInvalid={!!error.background_color} />
                                                <Form.Control.Feedback type="invalid">{error.background_color}</Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Cadastrar Dados
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )

};

export const BillFilter = ({ setFilter }) => {
    const handleClose = () => setShowDefault(false);
    const [showDefault, setShowDefault] = useState(false);
    const [search, setSearch] = useState("");
    const [supplierId, setSupplierId] = useState("");
    const [statusId, setStatusId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const { data: suppliers, isLoading: suppliersLoading } = FetchSuppliers();
    const { data: status, isLoading: statusLoading } = FetchStatus();

    var today = moment();
    var valid = function (current) {
        return current.isBefore(today);
    };

    var notSuperior = function (current) {
        return current.isAfter(startDate) && current.isBefore(today);
    }

    useEffect(() => {
        handleSubmit();
    }, [search]);

    let clearFilters = async (e) => {
        if (e) e.preventDefault();
        const response = ({ search: "", supplierId: "", statusId: "", startDate: "", endDate: "", formattedStartDate: "", formattedEndDate: "" });
        setSearch("");
        setSupplierId("");
        setStatusId("");
        setStartDate("");
        setEndDate("");
        setFilter(response);
        handleClose();
    };

    let handleSubmit = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search, supplierId, statusId, startDate, endDate });
        setFilter(response);
        handleClose();
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="primary" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faFilter} /></Button>

                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Filtros</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Filtrar por Dados</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Empresa</Form.Label>
                                                <Typeahead
                                                    id="supplier"
                                                    labelKey={suppliers => `${suppliers.name}`}
                                                    onChange={value => value[0] && setSupplierId(value[0].id)}
                                                    options={suppliers}
                                                    placeholder="Selecione uma Empresa"
                                                    isLoading={suppliersLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Status</Form.Label>
                                                <Typeahead
                                                    id="status"
                                                    labelKey={status => `${status.name}`}
                                                    onChange={value => value[0] && setStatusId(value[0].id)}
                                                    options={status}
                                                    placeholder="Selecione um Status"
                                                    isLoading={statusLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2">Filtrar por Período</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data Inicio</Form.Label>
                                                <Datetime
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={setStartDate}
                                                    isValidDate={valid}
                                                    renderInput={(props, openCalendar) => (
                                                        <InputGroup>
                                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                value={startDate ? moment(startDate).format("DD/MM/YYYY") : ""}
                                                                placeholder="dd/mm/yyyy"
                                                                onFocus={openCalendar}
                                                                onChange={() => { }} />
                                                        </InputGroup>
                                                    )} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data Final</Form.Label>
                                                <Datetime
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={setEndDate}
                                                    isValidDate={notSuperior}
                                                    renderInput={(props, openCalendar) => (
                                                        <InputGroup>
                                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                value={endDate ? moment(endDate).format("DD/MM/YYYY") : ""}
                                                                placeholder="dd/mm/yyyy"
                                                                onFocus={openCalendar}

                                                                onChange={() => { }} />
                                                        </InputGroup>
                                                    )} />
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={clearFilters}>
                                        Limpar Filtros
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Aplicar Filtros
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )
};

export const SaleFilter = ({ setFilter }) => {
    const [search, setSearch] = useState("");
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [statusId, setStatusId] = useState("");
    const [workerId, setWorkerId] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [paymentId, setPaymentId] = useState("");

    const { data: status, isLoading: statusLoading } = FetchStatus();
    const { data: workers, isLoading: workersLoading } = FetchUsers();
    const { data: customers, isLoading: customersLoading } = FetchCustomers();
    const { data: payments, isLoading: paymentsLoading } = FetchPayment();


    var today = moment();
    var valid = function (current) {
        return current.isBefore(today);
    };

    var notSuperior = function (current) {
        return current.isAfter(startDate) && current.isBefore(today);
    }

    let clearFilters = async (e) => {
        if (e) e.preventDefault();
        const response = ({ statusId: "", workerId: "", customerId: "", startDate: "", endDate: "", paymentId: ""});
        setWorkerId("");
        setStatusId("");
        setCustomerId("");
        setPaymentId("");
        setStartDate("");
        setEndDate("");
        setFilter(response);
        handleClose();
    };

    let handleSubmit = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ statusId, workerId, customerId, paymentId, startDate, endDate });
        setFilter(response);
        handleClose();
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-end align-items-center">
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="primary" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faFilter} /></Button>

                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Filtros</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Filtrar por Dados</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Cliente</Form.Label>
                                                <Typeahead
                                                    id="customer"
                                                    labelKey={customers => `${customers.name}`}
                                                    onChange={value => value[0] && setCustomerId(value[0].id)}
                                                    options={customers}
                                                    placeholder="Selecione um Cliente"
                                                    isLoading={customersLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Funcionário</Form.Label>
                                                <Typeahead
                                                    id="worker"
                                                    labelKey={workers => `${workers.username}`}
                                                    onChange={value => value[0] && setWorkerId(value[0].id)}
                                                    options={workers}
                                                    placeholder="Selecione um Funcionário"
                                                    isLoading={workersLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Mét. Pagamento</Form.Label>
                                                <Typeahead
                                                    id="payment"
                                                    labelKey={payments => `${payments.name}`}
                                                    onChange={value => value[0] && setPaymentId(value[0].id)}
                                                    options={payments}
                                                    placeholder="Selecione uma Forma de Pagamento"
                                                    isLoading={paymentsLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Status</Form.Label>
                                                <Typeahead
                                                    id="status"
                                                    labelKey={status => `${status.name}`}
                                                    onChange={value => value[0] && setStatusId(value[0].id)}
                                                    options={status}
                                                    placeholder="Selecione um Status"
                                                    isLoading={statusLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <h5 className="mb-2">Filtrar por Período</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data Inicio</Form.Label>
                                                <Datetime
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={setStartDate}
                                                    isValidDate={valid}
                                                    renderInput={(props, openCalendar) => (
                                                        <InputGroup>
                                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                value={startDate ? moment(startDate).format("DD/MM/YYYY") : ""}
                                                                placeholder="dd/mm/yyyy"
                                                                onFocus={openCalendar}

                                                                onChange={() => { }} />
                                                        </InputGroup>
                                                    )} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data Final</Form.Label>
                                                <Datetime
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={setEndDate}
                                                    isValidDate={notSuperior}
                                                    renderInput={(props, openCalendar) => (
                                                        <InputGroup>
                                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                                            <Form.Control
                                                                type="text"
                                                                value={endDate ? moment(endDate).format("DD/MM/YYYY") : ""}
                                                                placeholder="dd/mm/yyyy"
                                                                onFocus={openCalendar}

                                                                onChange={() => { }} />
                                                        </InputGroup>
                                                    )} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={clearFilters}>
                                        Limpar Filtros
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Aplicar Filtros
                                    </Button>
                                </Modal.Footer>
                            </Form>

                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )

};

export const RoleFilter = ({ setFilter, refreshState, setRefreshState}) => {
    const [search, setSearch] = useState("");
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);

    useEffect(() => {
        handleFilter();
    }, [search]);

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
    };

    const [name, setName] = useState("");
    const [salary, setSalary] = useState(0);
    const [abilities, setAbilities] = useState([]);
    const [error, setError] = useState({});

    const { data: ability, isLoading: abilitiesLoading } = FetchAbilities();

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await CreateRole(name, salary, abilities);
            if (response.data.status === 'success') {
                refreshState == true ? setRefreshState(false) : setRefreshState(true);

                setName("");
                setSalary(0);
                setAbilities("");
                toast.success('Cargo cadastrada com sucesso!')
                handleClose();
                setError("");

            } else {
                throw new Error('Falha ao cadastrar função');
            }
        } catch (err) {
            if (err.response.data.errors) {
                setError(err.response.data.errors);
                toast.warn('Verifique os campos obrigatórios!');
            } else {
                toast.error('Ocorreu um erro ao cadastrar a função!');
            };
        }
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="success" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faPlus} /> Cadastrar Cargo</Button>

                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Cadastro de Cargo</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Dados do Cargo</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control type="text" placeholder='Nome Exemplo' value={name} onChange={e => setName(e.target.value)} isInvalid={!!error.name} />
                                                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Salário</Form.Label>
                                                <Form.Control type="text" value={salary} onChange={e => setSalary(e.target.value)} placeholder='R$ 00.00' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Permissões do Cargo</Form.Label>  <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip style={{ margin: 0 }}>Você pode selecionar mais de uma opção segurando a tecla CTRL e clicando.</Tooltip>}>
                                                        <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
                                                    </OverlayTrigger>
                                                    <Typeahead
                                                        id="abilities"
                                                        className="is-invalid"
                                                        labelKey={ability => `${ability.label}`}
                                                        onChange={value => setAbilities(value)}
                                                        options={ability}
                                                        placeholder="Selecione um Cargo"
                                                        multiple
                                                        isLoading={abilitiesLoading}
                                                        renderMenuItemChildren={(ability) => (
                                                            <React.Fragment>
                                                                <div key={ability.value}>{ability.label}</div>
                                                                <strong><small>{ability.title}</small></strong>
                                                            </React.Fragment>
                                                        )}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Cadastrar Dados
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div >
    )

};

export const WorkerFilter = ({ setFilter, refreshState, setRefreshState}) => {
    const [search, setSearch] = useState("");
    const [role, setRole] = useState("");
    const [showDefault, setShowDefault] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const handleCloseFilter = () => setShowFilter(false);
    const handleClose = () => setShowDefault(false);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [roleId, setRoleId] = useState("");
    const [error, setError] = useState({});

    const { data: roles, isLoading: roleLoading } = FetchRoles();

    useEffect(() => {
        handleFilter();
    }, [search]);

    let clearFilters = async (e) => {
        if (e) e.preventDefault();
        const response = ({ search: "", role: "" });
        setSearch("");
        setRole("");
        setFilter(response);
        handleCloseFilter();
    };

    let handleFilter = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search, role });
        setFilter(response);
        handleCloseFilter();

    };

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await CreateUser(username, email, password, password_confirmation, roleId);
            if (response.data.status === 'success') {
                refreshState == true ? setRefreshState(false) : setRefreshState(true);
                setUsername("");
                setEmail("");
                setPassword("");
                setPasswordConfirmation("");
                setRoleId(0);
                toast.success('Funcionário cadastrado com sucesso!')
                handleClose();
                setError("");
            } else {
                throw new Error('Falha ao cadastrar funcionário');
            }
        } catch (err) {
            if (err.response.data.errors) {
                setError(err.response.data.errors);
                toast.warn('Verifique os campos obrigatórios!');
            } else {
                toast.error('Ocorreu um erro ao cadastrar o funcionário!');

            };
        }
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="success" className="my-3 me-2" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faPlus} /> Cadastrar Funcionário</Button>
                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Cadastro de Funcionário</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Dados do Funcionário</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder='Nome Exemplo' isInvalid={!!error.username} />
                                                <Form.Control.Feedback type="invalid">{error.username}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder='exemplo@exemplo.com' isInvalid={!!error.email} />
                                                <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Senha</Form.Label>
                                                <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder='Senha' isInvalid={!!error.password} />
                                                <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Confirmação de Senha</Form.Label>
                                                <Form.Control type="password" value={password_confirmation} onChange={e => setPasswordConfirmation(e.target.value)} placeholder='Confirmação de Senha' isInvalid={!!error.password} />
                                                <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Cargo</Form.Label>
                                            <Typeahead
                                                id="role"
                                                className="is-invalid"
                                                labelKey={roles => `${roles.name}`}
                                                onChange={value => value[0] && setRoleId(value[0].id)}
                                                options={roles}
                                                placeholder="Selecione um Cargo"
                                                isLoading={roleLoading}
                                            />
                                            <Form.Control.Feedback type="invalid">{error.roleId}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Cadastrar Dados
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                    <React.Fragment>
                        <Button variant="primary" className="my-3" onClick={() => setShowFilter(true)}> <FontAwesomeIcon icon={faFilter} /></Button>
                        <Modal size={'md'} as={Modal.Dialog} centered show={showFilter} onHide={handleCloseFilter}>
                            <Modal.Header>
                                <Modal.Title className="h6">Filtros</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleCloseFilter} />
                            </Modal.Header>
                            <Form onSubmit={handleFilter}>
                                <Modal.Body>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Funções</Form.Label>
                                                <Typeahead
                                                    id="role"
                                                    className="is-invalid"
                                                    labelKey={roles => `${roles.name}`}
                                                    onChange={value => value[0] && setRole(value[0].id)}
                                                    options={roles}
                                                    placeholder="Selecione um Cargo"
                                                    isInvalid={!!error.roleId}
                                                    isLoading={roleLoading}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={clearFilters}>
                                        Limpar Filtros
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Aplicar Filtros
                                    </Button>
                                </Modal.Footer>
                            </Form>

                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )

};

export const CustomerFilter = ({ setFilter }) => {
    const [search, setSearch] = useState("");

    useEffect(() => {
        handleSubmit();
    }, [search]);

    let handleSubmit = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search });
        setFilter(response);
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center my-3">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
};

export const SupplierFilter = ({ setFilter }) => {
    const handleClose = () => setShowDefault(false);
    const [showDefault, setShowDefault] = useState(false);
    const [search, setSearch] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const { data: categories, isLoading: categoryLoading } = FetchCategories();

    useEffect(() => {
        handleSubmit();
    }, [search]);

    let clearFilters = async (e) => {
        if (e) e.preventDefault();
        const response = ({ search: "", categoryId: "" });
        setSearch("");
        setCategoryId("");
        setFilter(response);
        handleClose();
    };

    let handleSubmit = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search, categoryId });
        setFilter(response);
        handleClose();
    };

    return (
        <div className="table-settings">
            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="primary" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faFilter} /></Button>

                        <Modal size={'md'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Filtros</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Categoria</Form.Label>
                                                <Typeahead
                                                    id="category"
                                                    className="is-invalid"
                                                    labelKey={categories => `${categories.name}`}
                                                    onChange={value => value[0] && setCategoryId(value[0].id)}
                                                    options={categories}
                                                    placeholder="Selecione uma Categoria"
                                                    isLoading={categoryLoading}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={clearFilters}>
                                        Limpar Filtros
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Aplicar Filtros
                                    </Button>
                                </Modal.Footer>
                            </Form>

                        </Modal>
                    </React.Fragment>
                </Col>

            </Row>
        </div>
    )
};

export const ProductFilter = ({ setFilter }) => {
    const handleClose = () => setShowDefault(false);
    const [showDefault, setShowDefault] = useState(false);
    const [supplierId, setSupplierId] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [search, setSearch] = useState("");
    const [barcode, setBarcode] = useState("");
    const [price, setPrice] = useState("");
    const { data: categories, isLoading: categoryLoading } = FetchCategories();
    const { data: suppliers, isLoading: suppliersLoading } = FetchSuppliers();

    useEffect(() => {
        handleSubmit();
    }, [search]);

    let clearFilters = async (e) => {
        if (e) e.preventDefault();
        const response = ({ search: "", supplierId: "", categoryId: "", barcode: "", price: "" });

        setSupplierId("");
        setCategoryId("");
        setSearch("");
        setBarcode("");
        setPrice("");
        setFilter(response);
        handleClose();
    };

    let handleSubmit = async (e) => {
        if (e) e.preventDefault();
        const response = await ({ search, supplierId, categoryId, barcode, price });
        setFilter(response);
        handleClose();
    };

    return (
        <div className="table-settings">

            <Row className="justify-content-between align-items-center">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Procurar na Tabela" value={search} onChange={e => setSearch(e.target.value)} />
                    </InputGroup>
                </Col>
                <Col xs={6} className="ps-md-0 text-end">
                    <React.Fragment>
                        <Button variant="primary" className="my-3" onClick={() => setShowDefault(true)}> <FontAwesomeIcon icon={faFilter} /></Button>

                        <Modal size={'xl'} as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title className="h6">Filtros</Modal.Title>
                                <Button variant="close" aria-label="Close" onClick={handleClose} />
                            </Modal.Header>
                            <Form onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Row>
                                        <h5 className="mb-2">Filtrar por Dados</h5>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Cód. Barras</Form.Label>
                                                <Form.Control type="number" value={barcode} onChange={e => setBarcode(e.target.value)} placeholder='0000000000000' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group id="zip">
                                                <Form.Label>Preço de Venda</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text><FontAwesomeIcon icon={faCoins} /></InputGroup.Text>
                                                    <Form.Control type="number" value={price} onChange={e => setPrice(e.target.value)} placeholder="R$ 00.00" />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Fornecedor</Form.Label>
                                                <Typeahead
                                                    id="supplier"
                                                    className="is-invalid"
                                                    labelKey={suppliers => `${suppliers.name}`}
                                                    onChange={value => value[0] && setSupplierId(value[0].id)}
                                                    options={suppliers}
                                                    placeholder="Selecione um Fornecedor"
                                                    isLoading={suppliersLoading}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group className="mb-2">
                                                <Form.Label>Categoria</Form.Label>
                                                <Typeahead
                                                    id="category"
                                                    className="is-invalid"
                                                    labelKey={categories => `${categories.name}`}
                                                    onChange={value => value[0] && setCategoryId(value[0].id)}
                                                    options={categories}
                                                    placeholder="Selecione uma Categoria"
                                                    isLoading={categoryLoading}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="danger" onClick={clearFilters}>
                                        Limpar Filtros
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Aplicar Filtros
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </React.Fragment>
                </Col>
            </Row>
        </div >
    )
};