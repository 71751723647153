
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCashRegister, faChartLine, faHome, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Tab, Breadcrumb, Form, InputGroup, Stack, Container } from 'react-bootstrap';
import Datetime from "react-datetime";
import { FinancialHeaderWidget, WeekDaysEarningsWidget, GainsWidget } from "../../components/Widgets";
import { trafficShares, totalOrders } from "../../data/charts";
import moment from "moment-timezone";
import { PaymentMethodGainsChart, YearlyGainsChart } from "../../components/Charts";
import { FetchExpense, FetchIncome, FetchProfit, FetchTopPayments, FetchWeeklyGains, FetchYearlyGains } from "../../services/ReportService";
import Skeleton from 'react-loading-skeleton'
import { FetchPayment } from "../../services/PaymentService";

export default () => {
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment());

    var today = moment();
    var valid = function (current) {
        return current.isBefore(today);
    };

    var notSuperior = function (current) {
        return current.isAfter(startDate) && current.isBefore(today);
    }


    const { data: incomeData, isLoading: IncomeLoading } = FetchIncome(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const { data: profitData, isLoading: ProfitLoading } = FetchProfit(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const { data: expenseData, isLoading: ExpenseLoading } = FetchExpense(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    const WeekdaysEarningData = [{ name: 'Segunda-Feira', Receita: 400 }, { name: 'Terça-Feira', Receita: 200 }, { name: 'Quarta-Feira', Receita: 300 }, { name: 'Quinta-Feira', Receita: 100 }, { name: 'Sexta-Feira', Receita: 300 }, { name: 'Sabado', Receita: 300 }];
    const { data: weeklyData, isLoading: weeklyLoading } = FetchWeeklyGains(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    const { data: yearlyData, isLoading: yearlyLoading } = FetchYearlyGains();
    const { data: paymentData, isLoading: paymentLoading } = FetchTopPayments(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    const yearlyStroke = { stroke_2021: "#FFAB33", stroke_2022: "#1A43F4", stroke_2023: "#FF0000", stroke_2023_proj: "#FF9200" }

    const DatePicker = () => {
        return (
            <Form.Group as={Col} sm={"auto"}>
                <InputGroup>
                    <InputGroup.Text className="calendarInput"><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    <Form.Group>
                        <Datetime
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={setStartDate}
                            isValidDate={valid}
                            renderInput={(props, openCalendar) => (
                                <Form.Control
                                    className="calendarInput"
                                    type="text"
                                    value={startDate ? moment(startDate).format("DD/MM/YYYY") : ""}
                                    placeholder="dd/mm/yyyy"
                                    onFocus={openCalendar}
                                    style={{ borderRight: "0px", borderLeft: "0px", width: "8rem" }}
                                    onChange={() => { }}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Datetime
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={setEndDate}
                            isValidDate={valid}
                            renderInput={(props, openCalendar) => (
                                <Form.Control
                                    className="calendarInput"
                                    type="text"
                                    value={endDate ? moment(endDate).format("DD/MM/YYYY") : ""}
                                    placeholder="dd/mm/yyyy"
                                    onFocus={openCalendar}
                                    style={{ borderLeft: "0px", width: "8rem" }}
                                    onChange={() => { }}
                                />
                            )}
                        />
                    </Form.Group>
                </InputGroup>
            </Form.Group>
        );
    };

    return (
        <div className="mt-4">
            <Breadcrumb style={{ width: "fit-content" }} className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
                <Breadcrumb.Item active>Financeiro</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4 justify-content-between">
                <Col md={"auto"} className="d-block mb-4 mb-md-0">
                    <h1 className="h2">Financeiro</h1>
                </Col>
                <DatePicker />
            </Row>

            <Row>
                <Col xs={12} lg={4} xl={4}>
                    <Card className="bg-white shadow-sm mb-3">
                        <Card.Body>
                            <FinancialHeaderWidget
                                title="Receita"
                                value={!IncomeLoading ? incomeData.income : <Skeleton inline width={"20%"} />}
                                color="text-success"
                                stroke="#05A677"
                                period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                data={incomeData.data}
                                percentage={!IncomeLoading ? incomeData.percent_change : <Skeleton inline width={"10%"} />}
                            />

                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={4} xl={4}>
                    <Card border="light" className="bg-white shadow-sm mb-3">
                        <Card.Body>
                            <FinancialHeaderWidget
                                title="Lucros"
                                value={!ProfitLoading ? profitData.profit : <Skeleton inline width={"20%"} />}
                                color="text-success"
                                stroke="#05A677 "
                                period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                data={profitData.data}
                                percentage={!ProfitLoading ? profitData.percent_change : <Skeleton inline width={"10%"} />}

                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={4} xl={4}>
                    <Card border="light" className="bg-white shadow-sm mb-3">
                        <Card.Body>
                            <FinancialHeaderWidget
                                title="Despesas"
                                color="text-danger"
                                value={!ExpenseLoading ? expenseData.expenses : <Skeleton inline width={"20%"} />}
                                stroke="#FA5252"
                                reverse
                                period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                data={expenseData.data}
                                percentage={!ExpenseLoading ? expenseData.percent_change : <Skeleton inline width={"10%"} />}

                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Card border="light" className="bg-white shadow-sm mb-3">
                        <Card.Header className="pb-0">
                            <Card.Title className="mb-0">Análise de Faturamento</Card.Title>
                            <small>{`${moment().subtract(1, 'year').format('YYYY')} - ${moment().format('YYYY')}`}</small>
                        </Card.Header>
                        <Card.Body>
                            <Container className="p-0" style={{ minWidth: "100%", height: "450px" }}>
                                <YearlyGainsChart data={yearlyData.data} start_date={moment().subtract(1, 'year').format('YYYY')} end_date={moment().format('YYYY')} stroke={yearlyStroke} />
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-3">
                        <Card.Header className="pb-0">
                            <Card.Title className="mb-0">Receita Média</Card.Title>
                            <small>{`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}</small>
                        </Card.Header>
                        <Card.Body>
                            <Stack direction="horizontal" className="justify-content-center" gap={4}>
                                <GainsWidget
                                    title="Média Diária"
                                    value={!weeklyLoading ? weeklyData.daily : <Skeleton inline width={"20%"} />}
                                    color="text-success"
                                    period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                    percentage={18.2}
                                />
                                <div className="vr" />

                                <GainsWidget
                                    title="Média Semanal"
                                    value={!weeklyLoading ? weeklyData.week : <Skeleton inline width={"20%"} />}
                                    color="text-success"
                                    period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                    percentage={18.2}
                                />
                                <div className="vr" />

                                <GainsWidget
                                    title="Média Mensal"
                                    value={!weeklyLoading ? weeklyData.month : <Skeleton inline width={"20%"} />}
                                    color="text-success"
                                    period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                    percentage={18.2}
                                />
                                <div className="vr" />

                            </Stack>

                            <WeekDaysEarningsWidget
                                color="text-info"
                                fill="#337ffb "
                                period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                data={weeklyData.data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4}>
                    <Card border="light" className="bg-white shadow-sm mb-3">
                        <Card.Header className="pb-0">
                            <Card.Title className="mb-0">Receita por Métd. Pagamento</Card.Title>
                            <small>{`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}</small>

                        </Card.Header>
                        <Card.Body>
                            <Stack direction="horizontal" className="justify-content-center" gap={6}>
                                <GainsWidget
                                    title="Receita Total"
                                    value={!paymentLoading ? paymentData.sum_gains : <Skeleton inline width={"20%"} />}
                                    color="text-success"
                                    period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                />

                                <div className="vr" />

                                <GainsWidget
                                    title="Taxas"
                                    value={!paymentLoading ? paymentData.sum_taxes : <Skeleton inline width={"20%"} />}
                                    color="text-danger"
                                    period={`${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`}
                                />
                            </Stack>
                            <Container className="p-0" style={{ minWidth: "100%", height: "350px" }}>
                                <PaymentMethodGainsChart data={paymentData.data} />
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div >
    );
};
