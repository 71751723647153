
import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { CustomersWidget, ProductTableWidget, PaymentMethodWidget, FindProductWidget } from "../../components/Widgets";

export default () => {
    const [saleData, setSaleData] = useState({});

    const [customerData, setCustomerData] = useState({});
    const [paymentData, setPaymentData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [cartArray, setCartArray] = useState([]);

    const [error, setError] = useState({});
    const [response, setResponse] = useState();

    console.log(saleData);

    useEffect(() => {
        if (customerData.userId) {
            setSaleData({
                customerData,
                cartArray,
                paymentData
            });
        }
    }, [customerData, cartArray, paymentData])

    useEffect(() => {
        if (productData.barcode) {
            setCartArray([...cartArray, productData].reverse());
        }
    }, [productData]);

    useEffect(() => {
        if (response) {
            setCustomerData({});
            setPaymentData({});
            setProductData([]);
            setCartArray([]);
            setSaleData({})
            setResponse(false);
        }
    }, [response])


    return (
        <div>
            <Row className="mt-4">
                <Col xs={12} xl={6}>
                    <CustomersWidget setCustomerData={setCustomerData} response={response} />
                    <FindProductWidget setResponse={setResponse} setError={setError} SaleData={saleData} setProductData={setProductData} />
                </Col>
                <Col xs={12} xl={6}>
                    <ProductTableWidget response={response} paymentData={paymentData} cartArray={cartArray} setCartArray={setCartArray} />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={12} xl={{ offset: 6, span: 6 }}>
                    <PaymentMethodWidget response={response} error={error} setPaymentData={setPaymentData} cartArray={cartArray} />
                </Col>

            </Row>
        </div>



    );
};
