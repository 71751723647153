import { useEffect, useState } from 'react';
import api from '../api'



export const FindActiveSales = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/sale?page=${page}&customerId=${filter.customerId ?? ''}&workerId=${filter.workerId ?? ''}&paymentId=${filter.paymentId ?? ''}&statusId=${filter.statusId ?? ''}&startDate=${filter.startDate ?? ''}&endDate=${filter.endDate ?? ''}`)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.sales);
                setPagination(response.data.data.pagination);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);

            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };
};

export const FindSaleId = async (id) => {
    return await api.get(`v1/sale/${id}`).then(function (response) {
        var res = response.data;
        return { data: res.data, status: res.status };
    });
};

export const CreateSale = async (customerId, workerId, total, subTotal, discount, paidAmount, paymentMethod, productId, productWeight, productSellPrice, productQuantity) => {
    const response = await api.post('/v1/sale', {
        customerId: customerId,
        workerId: workerId,
        total: total,
        subTotal: subTotal,
        discount: discount,
        paidAmount: paidAmount,
        paymentMethod: paymentMethod,
        productId: productId,
        productWeight: productWeight,
        productSellPrice: productSellPrice,
        productQuantity: productQuantity
    });

    return response;
};

export const UpdateSale = async (id, customerId, workerId, paymentMethod, status, paidAmount) => {
    const response = await api.put(`/v1/sale/${id}`, {
        customer_id: customerId,
        worker_id: workerId,
        paymentMethod: paymentMethod,
        status: status,
        paidAmount: paidAmount.replace(",", "."),
    });

    return response;
};

export const DeleteSale = async (id) => {
    return await api.delete(`/v1/sale/${id}`);
};

export const FetchStatus = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/list/status`)
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);
                setData(response.data.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])
    return { data, isLoading, isEmpty };
}

export const FetchSaleNumber = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get('v1/list/sales')
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);
                setData(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])
    return { data, isLoading, isEmpty };
}

