import { useEffect, useState } from 'react';
import api from '../api'

export const FindActiveUsers = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/v1/user?page=${page}&username=${filter.search ?? ''}&role=${filter.role ?? ''} `)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.users);
                setPagination(response.data.data.pagination);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };
}

export const FindUserId = async (id) => {
    return await api.get(`v1/user/${id}`).then(function (response) {
      var res = response.data;
      return { data: res.data, status: res.status };
    });
  };

export const CreateUser = async (username, email, password, password_confirmation, roleId) => {
    const response = await api.post('/v1/user', {
        username: username,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        roleId: roleId
    });

    return response;
};

export const UpdateUser = async (id, username, email, roleId) => {
    const response = await api.put(`/v1/user/${id}`, {
        username: username,
        email: email,
        roleId: roleId
    });

    return response;
};

export const resetPassword = async (id) => {
    const response = await api.put(`/v1/user/p/${id}`);
    return response;
};

export const AuthenticateUser = async (username, password, remember = false) => {
    const response = await api.post('/login', {
        username: username,
        password: password,
    });

    localStorage.clear();
    sessionStorage.clear();

    if (remember) {
        localStorage.setItem("token", `Bearer ${response.data.data.access_token}`);
    } else {
        sessionStorage.setItem("token", `Bearer ${response.data.data.access_token}`);
    }


    return response;
};

export const LogoutUser = async () => {
    await api.post('/logout');
    
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
};

export const DeleteUser = async (id) => {
    return await api.delete(`/v1/user/${id}`);
};

export const GetCurrentUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get('/who-am-i')
            .then(response => {
                setData(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsLoading(false);

                if (error.response.status === 401)   setData('Unauthorized');


            });
    }, [])

    return { data, isLoading };
}

export const FetchUsers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
    setIsLoading(true);
    api.get(`v1/list/users`)
        .then(response => {
            if (!response.data.status === "error") setIsEmpty(true);

            setData(response.data.data);
            setIsLoading(false);

        })
        .catch(error => {
            setIsEmpty(true);
            setIsLoading(false);
        });
    }, [])
    return { data, isLoading, isEmpty };
}
