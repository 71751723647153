
import React from "react";
import { LineChart, Line, Tooltip, YAxis, XAxis, AreaChart, Area, Legend, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';



export const FinancialHeaderChart = ({ stroke, data }) => {
    const id = Math.floor(Math.random() * 5000);
    const FinancialHeaderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip p-2">
                    <p className="label text-primary">{`${label}`}</p>
                    <p className="value mb-0" style={{ color: payload[0].stroke }}>R$ {payload[0].value}</p>
                    {payload[1] && <p className="value mb-0" style={{ color: payload[1].stroke }}>ML {payload[1].value}%</p>}
                </div>
            );
        }

        return null;
    };
    return (
        <ResponsiveContainer width='100%'>
            <AreaChart data={data}
                margin={{
                    top: 10,
                    right: 20,
                    left: 5,
                    bottom: -30,
                }}>

                <defs>
                    <linearGradient id={id} x1={0} y1={0} x2={0} y2={1}>
                        <stop offset={'0%'} stopColor={stroke} stopOpacity={0.4} />
                        <stop offset={'75%'} stopColor={stroke} stopOpacity={0.05} />
                    </linearGradient>
                </defs>

                <Area type="monotone" dataKey="value" stroke={stroke} fill={`url(#${id})`} />
                <Area type="monotone" dataKey="profit_percent" stroke={"#fc7c21"} opacity={"0%"} />
                <XAxis dataKey="name" tick={false} />
                <Tooltip content={<FinancialHeaderTooltip />} wrapperStyle={{ zIndex: 1000 }} />
            </AreaChart>
        </ResponsiveContainer>
    )
};

export const WeekdaysEarningsChart = ({ fill, data }) => {
    const WeekdayTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip p-2">
                    <p className="label text-primary">{`${label}`}</p>
                    <p className="value mb-0" style={{ color: payload[0].stroke }}>R$ {payload[0].value}</p>
                    {payload[1] && <p className="value mb-0" style={{ color: payload[1].stroke }}>ML {payload[1].value}%</p>}
                </div>
            );
        }

        return null;
    };
    return (
        <ResponsiveContainer width='100%'>
            <BarChart data={data}
                margin={{
                    top: 10,
                    right: 80,
                    left: 70,
                    bottom: -5,
                }}
                barSize={60}>
                <Bar type="monotone" dataKey="sales" fill={fill} />
                <XAxis dataKey="weekday" scale="point" />

                <Tooltip content={<WeekdayTooltip/>}/>
            </BarChart>
        </ResponsiveContainer>
    )
};

export const YearlyGainsChart = ({ stroke, data, start_date, end_date }) => {
    return (
        <ResponsiveContainer width='100%'>
            <LineChart data={data}
                margin={{
                    top: 10,
                    right: 0,
                    left: 10,
                    bottom: -5,
                }}>
                <Line dataKey={start_date} stroke={stroke.stroke_2022} />
                <Line dataKey={end_date} stroke={stroke.stroke_2023} />
                <Line connectNulls dataKey={`${end_date}i`} stroke={stroke.stroke_2023_proj} strokeDasharray="3 3" />


                <XAxis dataKey="name" />
                <YAxis domain={[0, 20000]} allowDataOverflow={true} />
                <Tooltip />
                <Legend />
            </LineChart>

        </ResponsiveContainer>
    )
}

export const PaymentMethodGainsChart = ({ stroke, data }) => {
    return (
        <ResponsiveContainer width='100%'>
            <PieChart
                margin={{
                    top: 10,
                    right: 20,
                    left: -20,
                    bottom: -5,
                }}>
                <Pie data={data} dataKey={(d) => parseFloat(d.sum_sales)}>
                    {data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.background_color} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>

        </ResponsiveContainer>
    )
}



// export const SalesValueChart = () => {
//   const data = {
//     labels: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
//     series: [[1, 2, 2, 3, 3, 4]]
//   };

//   const options = {
//     low: 0,
//     showArea: true,
//     fullWidth: true,
//     axisX: {
//       position: 'end',
//       showGrid: true
//     },
//     axisY: {
//       // On the y-axis start means left and end means right
//       showGrid: false,
//       showLabel: false,
//       labelInterpolationFnc: value => `$${value / 1}k`
//     }
//   };

//   // const plugins = [
//   //   ChartistTooltip()
//   // ]

//   return (
//     <Chartist data={data} options={{...options}} type="Line" className="ct-series-g ct-double-octave" />
//   );
// };

// export const SalesValueChartphone = () => {
//   const data = {
//     labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//     series: [[1, 2, 2, 3, 3, 4, 3]]
//   };

//   const options = {
//     low: 0,
//     showArea: true,
//     fullWidth: false,
//     axisX: {
//       position: 'end',
//       showGrid: true
//     },
//     axisY: {
//       // On the y-axis start means left and end means right
//       showGrid: false,
//       showLabel: false,
//       labelInterpolationFnc: value => `$${value / 1}k`
//     }
//   };

//   // const plugins = [
//   //   ChartistTooltip()
//   // ]

//   return (
//     <Chartist data={data} options={{...options}} type="Line" className="ct-series-g ct-major-tenth" />
//   );
// };

// export const CircleChart = (props) => {
//   const { series = [], donutWidth = 20 } = props;
//   const sum = (a, b) => a + b;

//   const options = {
//     low: 0,
//     high: 8,
//     donutWidth,
//     donut: true,
//     donutSolid: true,
//     fullWidth: false,
//     showLabel: false,
//     labelInterpolationFnc: value => `${Math.round(value / series.reduce(sum) * 100)}%`,
//   }

//   // const plugins = [
//   //   ChartistTooltip()
//   // ]

//   return (
//     <Chartist data={{ series }} options={{...options }} type="Pie" className="ct-golden-section" />
//   );
// };

// export const BarChart = (props) => {
//   const { labels = [], series = [], chartClassName = "ct-golden-section" } = props;
//   const data = { labels, series };

//   const options = {
//     low: 0,
//     showArea: true,
//     axisX: {
//       position: 'end'
//     },
//     axisY: {
//       showGrid: false,
//       showLabel: false,
//       offset: 0
//     }
//   };

//   // const plugins = [
//   //   ChartistTooltip()
//   // ]

//   return (
//     <Chartist data={data} options={{...options}} type="Bar" className={chartClassName} />
//   );
// };
