import { useEffect, useState } from "react";
import api from "../api";

export const FindActiveProduct = (page, filter, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(
        `v1/product?page=${page}&product=${filter.search ?? ""}&supplierId=${
          filter.supplierId ?? ""
        }&categoryId=${filter.categoryId ?? ""}&barcode=${
          filter.barcode ?? ""
        }&sell_price=${filter.price ?? ""}`
      )
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.products);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, filter, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};

export const FindProduct = async (barcode) => {
  return await api.get(`v1/product/b/${barcode}`).then(function (response) {
    var res = response.data;
    return { data: res.data, status: res.status };
  });
};

export const FindProductId = async (id) => {
  return await api.get(`v1/product/${id}`).then(function (response) {
    var res = response.data;
    return { data: res.data, status: res.status };
  });
};

export const CreateProduct = async (
  supplierId,
  categoryId,
  amount,
  barcode,
  productCode,
  name,
  weight,
  buyCost,
  sellPrice
) => {
  const response = await api.post("/v1/product", {
    supplierId: supplierId,
    categoryId: categoryId,
    barcode: barcode,
    name: name,
    quantity: amount,
    weight: weight,
    product_code: productCode,
    buy_cost: buyCost,
    sell_price: sellPrice,
  });

  return response;
};

export const UpdateProduct = async (
  id,
  supplierId,
  categoryId,
  amount,
  barcode,
  productCode,
  name,
  weight,
  buyCost,
  sellPrice
) => {
  const response = await api.put(`/v1/product/${id}`, {
    supplierId: supplierId,
    categoryId: categoryId,
    barcode: barcode,
    name: name,
    quantity: amount,
    weight: weight,
    product_code: productCode,
    buy_cost: buyCost,
    sell_price: sellPrice,
  });

  return response;
};

export const DeleteProduct = async (id) => {
  return await api.delete(`/v1/product/${id}`);
};
