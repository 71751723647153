export const AppRoutes = {
    CreateProduct: { path: "/product/new" },
    ViewProduct: { path: "/product" },
    CreateSupplier: { path: "/supplier/new" },
    ViewSupplier: { path: "/supplier" },
    CreateCustomer: { path: "/customer/new" },
    ViewCustomer: { path: "/customer" },
    CreateSale: {path: "/sale/new"},
    ViewSales: { path: "/sale" },
    CreateBill: { path: "/bill/new" },
    ViewBill: { path: "/bill" },
    ViewWorker: { path: "/worker" },
    ViewPayment: { path: "/payment" },
    ViewRole: { path: "/role" },
    ViewCategory: { path: "/category" },
    ViewReport: { path: "/report/financial"},
    ViewStatistic: {path: "/report/statistic"},

    DashboardOverview: { path: "/" },
    Settings: { path: "/settings" },
    Signin: { path: "/login" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};