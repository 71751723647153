import { useEffect, useState } from 'react';
import api from '../api'



export const FindActiveSupplier = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/supplier?page=${page}&name=${filter.search ?? ''}&categoryId=${filter.categoryId ?? ''}`)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.suppliers);
                setPagination(response.data.data.pagination);
                setIsLoading(false);
            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);

            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };

}

export const FindSupplierId = async (id) => {
    return await api.get(`v1/supplier/${id}`).then(function (response) {
      var res = response.data;
      return { data: res.data, status: res.status };
    });
  };

export const CreateSupplier = async (name, categoryId, CNPJ, minOrder, seller, sellerPhone) => {
    const response = await api.post('/v1/supplier', {
        name: name,
        categoryId: categoryId,
        CNPJ: CNPJ,
        minOrder: minOrder,
        seller: seller,
        sellerPhone: sellerPhone,
    });

    return response;
};

export const UpdateSupplier = async (id, name, categoryId, CNPJ, minOrder, seller, sellerPhone) => {
    const response = await api.put(`/v1/supplier/${id}`, {
        name: name,
        categoryId: categoryId,
        CNPJ: CNPJ,
        minOrder: minOrder,
        seller: seller,
        sellerPhone: sellerPhone,
    });

    return response;
};

export const DeleteSupplier = async (id) => {
    return await api.delete(`/v1/supplier/${id}`);
};

export const FetchSuppliers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
    setIsLoading(true);
    api.get(`v1/list/suppliers`)
        .then(response => {
            if (!response.data.status === "error") setIsEmpty(true);

            setData(response.data.data);
            setIsLoading(false);

        })
        .catch(error => {
            setIsEmpty(true);
            setIsLoading(false);
        });
    }, [])
    return { data, isLoading, isEmpty };

}

