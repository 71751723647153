
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from 'react-bootstrap';

export default (props) => {
  const currentYear = moment().get("year");

  return (
      <footer className="footer">
        <Row>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0 ">
            <p className="mb-0 text-center text-xl-left copyright">© Copyright 2022 - {`${currentYear} `} | Feito com <span role="img" aria-label="gratitude">💙</span> por  <Card.Link href="https://www.linkedin.com/in/giovane-breno/" target="_blank" className="text-blue text-decoration-none fw-normal">
              Giovane Breno
            </Card.Link>
            </p>
          </Col>
        </Row>
      </footer>
  );
};
