import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faCreditCard,
  faEdit,
  faEllipsisH,
  faEye,
  faTable,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
  Badge,
  Spinner,
} from "react-bootstrap";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import { CategoryWidget, RoleWidget, PaymentWidget } from "./Widgets";
import { FindActiveUsers } from "../services/UserService";
import { FindActiveSales } from "../services/SaleService";
import { FindActiveSupplier } from "../services/SupplierService";
import { FindActivePayment } from "../services/PaymentService";
import { FindActiveCustomer } from "../services/CustomerService";
import { FindActiveProduct } from "../services/ProductService";
import { FindActiveRole } from "../services/RoleService";
import { FindActiveBill } from "../services/BillService";
import { FindActiveCategory } from "../services/CategoryService";
import {
  BillDetailModal,
  CustomerDetailModal,
  DeleteBillModal,
  DeleteCustomerModal,
  DeleteProductModal,
  DeleteSaleModal,
  DeleteSupplierModal,
  DeleteUserModal,
  ProductDetailModal,
  SaleDetailModal,
  SupplierDetailModal,
  UserDetailModal,
} from "./Modals";
import { PaginationControl } from 'react-bootstrap-pagination-control';


export const CategoryTable = ({ filter, refreshState, setRefreshState }) => {
  const [page, setPage] = useState(1);

  const { data, pagination, isLoading, isEmpty } = FindActiveCategory(
    page,
    filter,
    refreshState
  );

  const renderWidgets = () => {
    return data.map((category) => {
      return (
        <Col key={category.id} xs={4} className="mb-4 d-none d-sm-block">
          <CategoryWidget
            id={category.id}
            icon={faTable}
            name={category.name}
            setRefreshState={setRefreshState}
            refreshState={refreshState}
          />
        </Col>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body>
        <Row>
          {isLoading ? (
            <Col
              md={11}
              className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12"
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          ) : isEmpty ? (
            <Col
              md={11}
              className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12"
            >
              <span className="mt-2">Não há registros cadastrados</span>
            </Col>
          ) : (
            <>{renderWidgets()}</>
          )}
        </Row>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const RoleTable = ({ filter, refreshState, setRefreshState }) => {
  const [page, setPage] = useState(1);

  const { data, pagination, isLoading, isEmpty } = FindActiveRole(page, filter, refreshState);

  const renderWidgets = () => {
    return data.map((role) => {
      return (
        <Col key={role.id} xs={4} className="mb-4 d-none d-sm-block">
          <RoleWidget
            id={role.id}
            icon={faUserCog}
            rolename={role.name}
            salary={role.salary}
            users={role.registered_users}
            setRefreshState={setRefreshState}
            refreshState={refreshState}
          />
        </Col>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body>
        <Row>
          {isLoading ? (
            <Col
              md={11}
              className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12"
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          ) : isEmpty ? (
            <Col
              md={11}
              className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12"
            >
              <span className="mt-2">Não há registros cadastrados</span>
            </Col>
          ) : (
            <>{renderWidgets()}</>
          )}
        </Row>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const PaymentTable = ({ filter, refreshState, setRefreshState }) => {
  const [page, setPage] = useState(1);
  const { data, pagination, isLoading, isEmpty } = FindActivePayment(
    page,
    filter,
    refreshState
  );

  const renderWidgets = () => {
    return data.map((payment) => {
      return (
        <Col key={payment.id} xs={4} className="mb-4 d-none d-sm-block">
          <PaymentWidget
            id={payment.id}
            icon={faCreditCard}
            name={payment.name}
            tax={payment.tax}
            set_status={payment.status.name}
            textColor={payment.color}
            iconColor={payment.background_color}
            timesUsed={payment.times_used}
            setRefreshState={setRefreshState}
            refreshState={refreshState}
          />
        </Col>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body>
        <Row>
          {isLoading ? (
            <Col
              md={11}
              className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12"
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          ) : isEmpty ? (
            <Col
              md={11}
              className="d-flex align-items-center justify-content-center mt-2 position-absolute col-12"
            >
              <span className="mt-2">Não há registros cadastrados</span>
            </Col>
          ) : (
            <>{renderWidgets()}</>
          )}
        </Row>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const BillTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();

  const { data, pagination, isLoading, isEmpty } = FindActiveBill(
    page,
    filter,
    refreshState
  );

  const renderTable = () => {
    return data.map((bill) => {
      const backgroundColor =
        bill.next_expiration.message === "BOLETO FINALIZADO"
          ? "#3f6791"
          : bill.next_expiration.message === "BOLETO VENCIDO"
            ? "#e74c3c"
            : "#262b40";
      return (
        <tr key={bill.id}>
          <td>
            <span className="fw-bold text-info">#{bill.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{bill.supplier.name}</span>
          </td>
          <td>
            <span className="fw-normal">{bill.description}</span>
          </td>
          <td>
            <span className="fw-normal">{bill.parcels}</span>
          </td>
          <td>
            <span className="fw-bold text-success">
              R$ {parseFloat(bill.total).toFixed(2)}
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <Badge
                bg=""
                style={{ backgroundColor: backgroundColor }}
                className="badge me-1"
              >
                {bill.next_expiration.message}
              </Badge>
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <Badge
                bg=""
                style={{
                  backgroundColor: bill.status.background_color,
                  color: bill.status.color,
                }}
                className="badge me-1"
              >
                {bill.status.name}
              </Badge>
            </span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <BillDetailModal id={bill.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
                <Dropdown.Item className="text-danger">
                  <DeleteBillModal id={bill.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Empresa</th>
              <th className="border-bottom">Descrição</th>
              <th className="border-bottom">Parcelas</th>
              <th className="border-bottom">Valor da Nota</th>
              <th className="border-bottom">Próx. Vencimento</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const ProductTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveProduct(
    page,
    filter,
    refreshState
  );

  const renderTable = () => {
    return data.map((product) => {
      return (
        <tr key={product.id}>
          <td>
            <span className="fw-bold text-info">#{product.barcode.toString().padStart(12, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{product.supplier.name}</span>
          </td>
          <td>
            <span className="fw-normal">{product.name}</span>
          </td>
          <td>
            <span className="fw-normal">{product.weight} KG</span>
          </td>
          <td>
            <span className="fw-bold text-info">{product.storage} UN</span>
          </td>
          <td>
            <span className="fw-bold text-success">
              R$ {parseFloat(product.sell_price).toFixed(2)}
            </span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <ProductDetailModal
                    id={product.id}
                    setRefreshState={setRefreshState}
                    refreshState={refreshState}
                  />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeleteProductModal
                    id={product.id}
                    setRefreshState={setRefreshState}
                    refreshState={refreshState}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Fornecedor</th>
              <th className="border-bottom">Produto</th>
              <th className="border-bottom">Peso</th>
              <th className="border-bottom">Estoque</th>
              <th className="border-bottom">Preço</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>

        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <PaginationControl
            page={pagination.current_page}
            between={4}
            total={pagination.total}
            limit={pagination.per_page}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const CustomerTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();

  const { data, pagination, isLoading, isEmpty } = FindActiveCustomer(
    page,
    filter,
    refreshState
  );
  const renderTable = () => {
    return data.map((customer) => {
      return (
        <tr key={customer.id}>
          <td>
            <span className="fw-bold text-info">#{customer.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{customer.name}</span>
          </td>
          <td>
            <span className="fw-normal">
              {customer.street ?? '-'}, {customer.house_number ?? '-'}. {customer.district ?? '-'}
            </span>
          </td>
          <td>
            <span className="fw-normal">{customer.phone ?? 'Não Informado'}</span>
          </td>
          <td>
            <span className="fw-bold text-info"> {customer.count_sales ?? '00.00'}</span>
          </td>
          <td>
            <span className="fw-bold text-success">R$ {customer.total_spent ?? '0'}</span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <CustomerDetailModal id={customer.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeleteCustomerModal id={customer.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Nome</th>
              <th className="border-bottom">Endereço</th>
              <th className="border-bottom">Telefone</th>
              <th className="border-bottom">Pedidos</th>
              <th className="border-bottom">Total Gasto</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const SupplierTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();
  const { data, pagination, isLoading, isEmpty } = FindActiveSupplier(
    page,
    filter,
    refreshState
  );

  const renderTable = () => {
    return data.map((supplier) => {
      return (
        <tr key={supplier.id}>
          <td>
            <span className="fw-bold text-info">#{supplier.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{supplier.name}</span>
          </td>
          <td>
            <span className="fw-normal">{supplier.category.name} </span>
          </td>
          <td>
            <span className="fw-normal">{supplier.min_order ?? 'Não Informado'}</span>
          </td>
          <td>
            <span className="fw-bold text-info">{supplier.count_product}</span>
          </td>
          <td>
            <span className="fw-bold text-success">R$ {supplier.sum_bills}</span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <SupplierDetailModal id={supplier.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeleteSupplierModal id={supplier.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Nome da Empresa</th>
              <th className="border-bottom">Categoria</th>
              <th className="border-bottom">Pedido Mínimo</th>
              <th className="border-bottom">Produtos</th>
              <th className="border-bottom">Notas</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const SaleTable = ({ filter }) => {
  const [page, setPage] = useState(1);
  const [refreshState, setRefreshState] = useState();

  const { data, pagination, isLoading, isEmpty } = FindActiveSales(
    page,
    filter,
    refreshState
  );

  const renderTable = () => {
    return data.map((sale) => {
      return (
        <tr key={sale.id}>
          <td>
            <span className="fw-bold text-info">#{sale.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{sale.customer.name}</span>
          </td>
          <td>
            <span className="fw-normal">{sale.worker.name}</span>
          </td>
          <td>
            <span className="fw-bold text-success">
              R$ {parseFloat(sale.total).toFixed(2)}
            </span>
          </td>
          <td>
            <span className="fw-bold text-success">
              R$ {parseFloat(sale.paid_amount).toFixed(2)}
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <Badge
                bg=""
                style={{
                  backgroundColor: sale.payment_method.background_color,
                  color: sale.payment_method.color,
                }}
                className="badge me-1"
              >
                {sale.payment_method.name}{" "}
              </Badge>
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <Badge
                bg=""
                style={{
                  backgroundColor: sale.status.background_color,
                  color: sale.status.color,
                }}
                className="badge me-1"
              >
                {sale.status.name}
              </Badge>
            </span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <SaleDetailModal id={sale.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>

                <Dropdown.Item className="text-danger">
                  <DeleteSaleModal id={sale.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Cliente</th>
              <th className="border-bottom">Funcionário</th>
              <th className="border-bottom">Valor Total</th>
              <th className="border-bottom">Valor Pago</th>
              <th className="border-bottom">Mét. Pagamento</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

export const WorkerTable = ({ filter, refreshState, setRefreshState }) => {
  const [page, setPage] = useState(1);
  const { data, pagination, isLoading, isEmpty } = FindActiveUsers(
    page,
    filter,
    refreshState
  );

  const renderTable = () => {
    return data.map((user) => {
      return (
        <tr key={user.id}>
          <td>
            <span className="fw-bold text-info">#{user.id.toString().padStart(5, 0)}</span>
          </td>
          <td>
            <span className="fw-normal">{user.username}</span>
          </td>
          <td>
            <span className="fw-normal">{user.email}</span>
          </td>
          <td>
            <span className="fw-normal">{user.role.name}</span>
          </td>
          <td>
            <span className="fw-bold text-success">
              R$ {parseFloat(user.role.salary).toFixed(2)}
            </span>
          </td>
          <td>
            <span className="fw-normal">
              <Badge bg="success" className="badge me-1">
                {user.created_at}
              </Badge>
            </span>
          </td>
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="text-info">
                  <UserDetailModal id={user.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
                <Dropdown.Item className="text-danger">
                  <DeleteUserModal id={user.id} setRefreshState={setRefreshState} refreshState={refreshState} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Nome</th>
              <th className="border-bottom">Email</th>
              <th className="border-bottom">Cargo</th>
              <th className="border-bottom">Salário</th>
              <th className="border-bottom">Admissão</th>
              <th className="border-bottom">Ações</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner animation="border" role="status" />
                </td>
              </tr>
            ) : isEmpty ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <span className="mt-2">Não há registros cadastrados</span>
                </td>
              </tr>
            ) : (
              <>{renderTable()}</>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        <PaginationControl
          page={pagination.current_page}
          between={4}
          total={pagination.total}
          limit={pagination.per_page}
          changePage={(page) => {
            setPage(page);
          }}
          ellipsis={1}
        />
      </Card.Footer>
    </Card>
  );
};

