import { useEffect, useState } from 'react';
import api from '../api'



export const FindActiveCustomer = (page, filter, refreshState) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/customer?page=${page}&name=${filter.search ?? ''}`)
            .then(response => {
                response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
                setData(response.data.data.customers);
                setPagination(response.data.data.pagination);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);

            });
    }, [page, filter, refreshState])

    return { data, pagination, isLoading, isEmpty };

}

export const FindCustomerId = async (id) => {
    return await api.get(`v1/customer/${id}`).then(function (response) {
        var res = response.data;
        return { data: res.data, status: res.status };
    });
};

export const CreateCustomer = async (name, phone, street, district, houseNumber) => {
    const response = await api.post('/v1/customer', {
        name: name,
        phone: phone,
        street: street,
        district: district,
        house_number: houseNumber
    });

    return response;
};

export const UpdateCustomer = async (id, name, phone, street, district, houseNumber) => {
    const response = await api.put(`/v1/customer/${id}`, {
        name: name,
        phone: phone,
        street: street,
        district: district,
        house_number: houseNumber
    });

    return response;
};

export const DeleteCustomer = async (id) => {
    return await api.delete(`/v1/customer/${id}`);
};

export const FetchCustomers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`v1/list/customers`)
            .then(response => {
                if (!response.data.status === "error") setIsEmpty(true);

                setData(response.data.data);
                setIsLoading(false);

            })
            .catch(error => {
                setIsEmpty(true);
                setIsLoading(false);
            });
    }, [])

    return { data, isLoading, isEmpty };

}


