import { useEffect, useState } from "react";
import api from "../api";

export const FindActiveBill = (page, filter, refreshState) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(
        `v1/bill?page=${page}&description=${filter.search ?? ""}&supplierId=${filter.supplierId ?? ""
        }&statusId=${filter.statusId ?? ""}&startDate=${filter.startDate ?? ""
        }&endDate=${filter.endDate ?? ""}`
      )
      .then((response) => {
        response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
        setData(response.data.data.bills);
        setPagination(response.data.data.pagination);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsEmpty(true);
        setIsLoading(false);
      });
  }, [page, filter, refreshState]);

  return { data, pagination, isLoading, isEmpty };
};

export const FindBillId = async (id) => {
  return await api.get(`v1/bill/${id}`).then(function (response) {
    var res = response.data;
    return { data: res.data, status: res.status };
  });
};

export const CreateBill = async (
  supplier_id,
  total,
  description,
  inputFields
) => {
  let barcodes = [];
  let values = [];
  let due_dates = [];

  if (inputFields != null) {
    inputFields.forEach((input) => {
      barcodes.push(input.barcode);
      values.push(input.amount);
      due_dates.push(input.dueDate);
    });
  }
  const response = await api.post("/v1/bill", {
    supplier_id: supplier_id,
    total: total,
    description: (description !== "") ? description : undefined,
    barcode: barcodes,
    amount: values,
    due_date: due_dates,
  });

  return response;
};

export const UpdateBill = async (id, supplier_id, description) => {
  const response = await api.put(`/v1/bill/${id}`, {
    supplier_id: supplier_id,
    description: (description !== "") ? description : undefined,
  });

  return response;
};

export const UpdateBillNumber = async (id, status_id) => {
  const response = await api.put(`/v1/bill/b/${id}`, {
    status_id: status_id,
  });

  return response;
};


export const DeleteBill = async (id) => {
  return await api.delete(`/v1/bill/${id}`);
};
