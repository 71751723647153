import { useEffect, useState } from "react";
import api from "../api";

export const FetchIncome = (startDate, endDate) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api
            .get(
                `v1/finance/period/balance/income/${startDate ?? ""}/${endDate ?? ""}`
            )
            .then((response) => {
                setData(response.data.data);
                
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    return { data, isLoading };
};

export const FetchExpense = (startDate, endDate) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        setIsLoading(true);
        api
            .get(
                `v1/finance/period/balance/expense/${startDate ?? ""}/${endDate ?? ""}`
            )
            .then((response) => {
                setData(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    return { data, isLoading };
};

export const FetchProfit = (startDate, endDate) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        setIsLoading(true);
        api
            .get(
                `v1/finance/period/balance/profit/${startDate ?? ""}/${endDate ?? ""}`
            )
            .then((response) => {
                setData(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    return { data, isLoading };
};

export const FetchYearlyGains = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api
            .get(
                `v1/finance/period/balance/yearly`
            )
            .then((response) => {
                setData(response.data.data);
                
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, []);

    return { data, isLoading };
};

export const FetchTopPayments = (startDate, endDate) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api
            .get(
                `v1/finance/period/payment/${startDate ?? ""}/${endDate ?? ""}`
            )
            .then((response) => {
                setData(response.data.data);
                
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    return { data, isLoading };
};

export const FetchWeeklyGains = (startDate, endDate) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api
            .get(
                `v1/finance/period/balance/weekly/${startDate ?? ""}/${endDate ?? ""}`
            )
            .then((response) => {
                setData(response.data.data);
                
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [startDate, endDate]);

    return { data, isLoading };
};



