
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCashRegister, faChartLine, faHome, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Tab, Breadcrumb, Form, InputGroup, Stack, Container } from 'react-bootstrap';
import Datetime from "react-datetime";
import { FinancialHeaderWidget, WeekDaysEarningsWidget, GainsWidget } from "../../components/Widgets";
import { trafficShares, totalOrders } from "../../data/charts";
import moment from "moment-timezone";
import { PaymentMethodGainsChart, YearlyGainsChart } from "../../components/Charts";
import { FetchExpense, FetchIncome, FetchProfit, FetchTopPayments, FetchWeeklyGains, FetchYearlyGains } from "../../services/ReportService";
import Skeleton from 'react-loading-skeleton'
import { FetchPayment } from "../../services/PaymentService";

export default () => {
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment());

    var today = moment();
    var valid = function (current) {
        return current.isBefore(today);
    };


    const { data: incomeData, isLoading: IncomeLoading } = FetchIncome(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const { data: profitData, isLoading: ProfitLoading } = FetchProfit(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const { data: expenseData, isLoading: ExpenseLoading } = FetchExpense(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    const WeekdaysEarningData = [{ name: 'Segunda-Feira', Receita: 400 }, { name: 'Terça-Feira', Receita: 200 }, { name: 'Quarta-Feira', Receita: 300 }, { name: 'Quinta-Feira', Receita: 100 }, { name: 'Sexta-Feira', Receita: 300 }, { name: 'Sabado', Receita: 300 }];
    const { data: weeklyData, isLoading: weeklyLoading } = FetchWeeklyGains(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    const { data: yearlyData, isLoading: yearlyLoading } = FetchYearlyGains();
    const { data: paymentData, isLoading: paymentLoading } = FetchTopPayments(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    const yearlyStroke = { stroke_2021: "#FFAB33", stroke_2022: "#1A43F4", stroke_2023: "#FF0000", stroke_2023_proj: "#FF9200" }

    const DatePicker = () => {
        return (
            <Form.Group as={Col} sm={"auto"}>
                <InputGroup>
                    <InputGroup.Text className="calendarInput"><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                    <Form.Group>
                        <Datetime
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={setStartDate}
                            isValidDate={valid}
                            renderInput={(props, openCalendar) => (
                                <Form.Control
                                    className="calendarInput"
                                    type="text"
                                    value={startDate ? moment(startDate).format("DD/MM/YYYY") : ""}
                                    placeholder="dd/mm/yyyy"
                                    onFocus={openCalendar}
                                    style={{ borderRight: "0px", borderLeft: "0px", width: "8rem" }}
                                    onChange={() => { }}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Datetime
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={setEndDate}
                            isValidDate={valid}
                            renderInput={(props, openCalendar) => (
                                <Form.Control
                                    className="calendarInput"
                                    type="text"
                                    value={endDate ? moment(endDate).format("DD/MM/YYYY") : ""}
                                    placeholder="dd/mm/yyyy"
                                    onFocus={openCalendar}
                                    style={{ borderLeft: "0px", width: "8rem" }}
                                    onChange={() => { }}
                                />
                            )}
                        />
                    </Form.Group>
                </InputGroup>
            </Form.Group>
        );
    };

    return (
        <div className="mt-4">
            <Breadcrumb style={{ width: "fit-content" }} className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
                <Breadcrumb.Item active>Estatística</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="d-flex flex-wrap flex-md-nowrap align-items-center py-4 justify-content-between">
                <Col md={"auto"} className="d-block mb-4 mb-md-0">
                    <h1 className="h2">Estatística</h1>
                </Col>
                <DatePicker />
            </Row>

            <Row>
               
            </Row>
        </div >
    );
};
