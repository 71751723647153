import axios from 'axios';

var token = sessionStorage.getItem("token") || localStorage.getItem("token");

const api = axios.create({
  baseURL: 'http://api.cdrjardimsul.com.br/api/',
  headers: {
    Authorization: token,
  },
});


export default api;
